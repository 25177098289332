import { Component, OnInit, Input } from "@angular/core";
import bacteriaSubCategories from "../../assets/json/bacteriaSubCategories.json";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-comparison-categories",
  templateUrl: "./comparison-categories.component.html",
  styleUrls: ["./comparison-categories.component.css"],
})
export class ComparisonCategoriesComponent implements OnInit {
  @Input() data: any = {};

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: ["iprobio_blue", "iprobio_blue_light", "iprobio_green", "iprobio_red", "iprobio_blue_dark"],
    patris_health: [
      "patris_health_blue",
      "patris_health_green",
      "patris_health_purple",
      "patris_health_yellow",
      "patris_health_light_purple"
    ],
    astrolab: [
      "astrolab_blue",
      "astrolab_green",
      "astrolab_yellow",
      "astrolab_red",
      "astrolab_purple",
    ],
  }
  legendColors: any = this.legendColorsVariants['default'];
  constructor(public app: AppComponent) {}

  categories: any = [
    {
      category: "GUT_HEALTH",
      subcategories: [
        {
          title: "IMMUNE_STRENGTH",
          bacterias: ["Lactobacillus", "Bifidobacterium"],
        },
        {
          title: "GUT_WALL_STRENGTH",
          bacterias: ["Faecalibacterium", "Eubacterium"],
        },
        {
          title: "WEIGHT_REDUCTION_SUPPORT",
          bacterias: ["Akkermansia", "Hafnia-Obesumbacterium"],
        },
      ],
    },
    {
      category: "FIBER_DEGRADATION",
      subcategories: [
        {
          title: "FIBRE_DEGRADATION",
          bacterias: ["Methanobrevibacter", "Methanosphaera"],
        },
      ],
    },
    {
      category: "GUT_CHALLENGES",
      subcategories: [
        {
          title: "POTENTIAL_COLON_PROBLEMS_NEW",
          bacterias: ["Fusobacterium"],
        },
        {
          title: "INFECTION_ALARM_NEW",
          bacterias: ["Bacteroides"],
        },
        {
          title: "FAT_ALARM_NEW",
          bacterias: ["Bilophila", "Desulfovibrio"],
        },
      ],
    },
  ];

  lowRangeBacteria: number = 0;
  avgRangeBacteria: number = 0;
  highRangeBacteria: number = 0;
  overallResult: string;
  flipGraphColorItems: string[] = [
    "POTENTIAL_COLON_PROBLEMS_NEW",
    "INFECTION_ALARM_NEW",
    "FAT_ALARM_NEW",
  ];

  selectedBacteria: string;
  bacteriaCategories: any = [];

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
    }
    this.setBacteriaLevels();
  }
  sharesValue(item: any, i: any, values: any) {
    let flip = false;
    values.forEach((element: any, index: any) => {
      if (element !== item) {
        let tempItemLevel = item.position.split("(")[1].split("%")[0];
        let tempElementLevel = element.position.split("(")[1].split("%")[0];

        let diff = Math.abs(Number(tempItemLevel) - Number(tempElementLevel));
        if (diff < 1) {
          if (i > index) flip = true;
        }
      }
    });

    return flip;
  }
  setBacteriaLevels() {
    this.categories.forEach((category: any) => {
      let category_title = category.category;
      let subcategories = [];
      category.subcategories.forEach((subcategory: any) => {
        let subcategory_title = subcategory.title;
        let bacterias = [];
        let values = [];
        subcategory.bacterias.forEach((bacteria: any) => {
          this.data.forEach((elem: any) => {
            let tempValue = elem.top35.filter(
              (n: any) => n.bacteria == bacteria,
            )[0];
            if (tempValue) {
              // @ts-ignore: Object is possibly 'null'.
              const dif = ((tempValue.range[1] - tempValue.range[0]) / 70) * 15;
              // @ts-ignore: Object is possibly 'null'.
              const refUp = tempValue.range[1] + dif; // potential for gas production
              // @ts-ignore: Object is possibly 'null'.
              const refLow = tempValue.range[0] - dif; // potential for gas production
              // @ts-ignore: Object is possibly 'null'.
              let cal = ((tempValue.value - refLow) / (refUp - refLow)) * 100;
              if (cal < 0) {
                cal = 0;
              }
              if (cal > 100) {
                cal = 100;
              }

              let position = "calc(" + cal + "% - 15px)";

              let tempPosition = {
                date: elem.date,
                position: position,
              };

              values.push(tempPosition);
            }
          });

          let temp = {
            bacteria: bacteria,
            values: values,
          };

          bacterias.push(temp);
          values = [];
        });

        let tempSubCategory = {
          title: subcategory_title,
          bacterias: bacterias,
        };

        subcategories.push(tempSubCategory);
        bacterias = [];
      });

      let tempCategory = {
        title: category_title,
        subcategories: subcategories,
      };

      this.bacteriaCategories.push(tempCategory);
    });
  }
}
