import axios from "axios";
import { Injectable } from "@angular/core";
import { ISubject, ITestKit } from "interfaces/tests";
import { ITestKitUpdate } from "interfaces";
import { environment } from "../../../environments/environment";

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

@Injectable()
export class SubjectService {
  constructor() {}

  async GetSubjects(
    search_query_name?: string,
    search_query_kit_id?: string,
    search_query_kit_uid?: string,
    skip: number = 0
  ) {
    const params = new URLSearchParams();
    if (search_query_name)
      params.append("search_query_name", search_query_name);
    if (search_query_kit_id)
      params.append("search_query_kit_id", search_query_kit_id);
    if (search_query_kit_uid)
      params.append("search_query_kit_uid", search_query_kit_uid);
    params.append("skip", skip.toString());

    return axios.get<ISubject[]>(
      `/api/v1/subjects/?${params.toString()}`,
      authHeaders()
    );
  }

  async GetHCPSubjects() {
    return axios.get<ISubject[]>(`/api/v1/subjects/hcp_linked/`, authHeaders());
  }

  async TryConenctingHCPKit(testkit_uid: string) {
    return axios.post<ISubject[]>(
      `/api/v1/testkit_hcp_connection/create_within_range/${testkit_uid}`,
      {},
      authHeaders()
    );
  }

  async CreateSubject(data: ISubject) {
    return axios.post(`/api/v1/subjects/`, data, authHeaders());
  }

  async GetSubject(id: number) {
    return axios.get<ISubject>(`/api/v1/subjects/single/?subject_id=${id}`, authHeaders());
  }
  async GetSingleKit(id: number) {
    return axios.get<ISubject>(
      `/api/v1/test_kits/single?kit_id=${id}`,
      authHeaders()
    );
  }

  async UpdateSubject(data: ISubject) {
    return axios.put<ISubject>(`/api/v1/subjects/`, data, authHeaders());
  }

  async LinkTestKit(data: ITestKitUpdate, id: number) {
    return axios.put<ITestKit>(
      `/api/v1/result_uploads/add_subject/${id}`,
      data,
      authHeaders()
    );
  }

  async SubmitDietary(id: number) {
    return axios.post<ITestKit>(
      `/api/v1/dietary_intake_item/close_dietary`,
      { kit_id: id },
      authHeaders()
    );
  }
  async SendSampleReceivedEmail(kitId: number, design: string) {
    console.log(kitId);
    return axios.post(
      `/api/v1/utils/sample_received_email_hcp`,
      { kit_id: kitId, design: design },
      authHeaders()
    );
  }

  async ReopenDietary(id: number) {
    return axios.get<ITestKit>(
      `/api/v1/test_kits/reopen_dietary/${id}`,
      authHeaders()
    );
  }

  async GetTestKit(kitId: number) {
    return axios.get<ITestKit>(`/api/v1/test_kits/${kitId}`, authHeaders());
  }

  async GetSubjectByTestkit(testkitID: number) {
    return axios.get<ITestKit>(
      `/api/v1/test_kits/get_subject_id/${testkitID}`,
      authHeaders()
    );
  }
  async GetPdfs(kitId: number, lang: string) {
    console.log(kitId);
    return axios.get<ITestKit>(
      `/api/v1/test_kits/pdfs?kit_id=${kitId}&language_code=${lang}`,
      authHeaders()
    );
  }
}
