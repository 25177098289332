import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import axios from "axios";
import { environment } from "../../../environments/environment";

axios.defaults.baseURL = environment.url.api;

@Injectable()
export class AuthenticationService {
  constructor(public jwtHelper: JwtHelperService, public router: Router) {}

  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username.toLowerCase());
    params.append("password", password);
    return axios.post(`/api/v1/login/access-token`, params);
  }

  async passwordRecovery(email: string, design: string, languageCode: string) {
    if (design == "") {
      design = "default";
    }
    return axios.post(
      `/api/v1/password-recovery/${email}?lang_code=${languageCode}&design=${design}`
    );
  }

  async passwordReset(password: string, token: string) {
    return axios.post(`/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  }

  async activateAccount(token: string) {
    return axios.post(`/api/v1/activate-account/${token}`);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public logout(): boolean {
    localStorage.removeItem("token");
    document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    this.router.navigate(["test-results"]);
    return true;
  }
}
