import { Component, OnInit } from "@angular/core";
import * as Survey from "survey-angular";
import { Model, SurveyNG, NumericValidator } from "survey-angular";
import { SurveyService } from "../api/survey/survey";
import { ActivatedRoute, Router } from "@angular/router";
import { SubjectService } from "../api/test/subject";
import { IUserSurveyAnswer } from "../../../interfaces/surveys";
import { UtilityService } from "../utility.service";
import { Converter } from "showdown";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "../app.component";
import * as surveyData from "./survey-data.json";
import { NgZone } from "@angular/core";

// import 'survey-angular/modern.css';
Survey.StylesManager.applyTheme("bootstrap");

@Component({
  selector: "survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit {
  constructor(
    private surveyService: SurveyService,
    private testkitService: SubjectService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilityService,
    public app: AppComponent,
    private ngZone: NgZone // ✅ Inject NgZone
  ) {}

  userAnswers: IUserSurveyAnswer[] = [];
  surveyJSON;
  testResults;
  testSubject;
  testResultID;
  survey;
  questions;

  async ngOnInit() {
    this.testResultID = Number(this.route.snapshot.paramMap.get("id"));
    this.testResults = (
      await this.testkitService.GetTestKit(this.testResultID)
    ).data;

    if (!this.testResults) {
      console.error("❌ testResults is undefined!");
      return;
    }
    const myCss = {
      navigation: {
        complete: "sv_complete_btn",
        prev: "sv_prev_btn",
        next: "sv_next_btn",
      },
      root: "sv_main sv_bootstrap_css",
      container: "sv_container",
      progressTop: "progress center-block mx-auto mb-4",
      progressBar: "progress-bar",
      progressText: "sv-hidden",
      row: "sv_row",
      question: {
        mainRoot: "sv_qstn",
      },
      rating: {
        item: "sv-rating-default",
        selected: "sv-rating-selected",
      },
    };
    try {
      console.log(this.app.getEnvironmentType());
      Survey.surveyLocalization.defaultLocale = this.translate.currentLang;

      // ✅ Fetch survey from API
      let design = this.app.partnerVersion;
      if (design === "innerbuddies") {
        design = "default";
      }
      const response = await this.surveyService.GetSurveyNew(
        design,
        this.app.getEnvironmentType()
      );
      console.log("✅ Raw Survey API Response:", response.data);

      // ✅ Extract and format pages/questions correctly
      this.surveyJSON = this.extractQuestionsFromSurvey(response.data);

      // ✅ Store extracted questions for later use
      this.questions = this.extractAllQuestions(response.data); // ✅ Extract ALL questions

      if (!this.questions || this.questions.length === 0) {
        console.error("❌ No questions extracted!");
        return;
      }

      // ✅ Initialize the survey model
      this.survey = new Model(this.surveyJSON);
      this.survey.showCompletedPage = false; 

      let converter = new Converter();
      this.survey.onTextMarkdown.add(function (survey, options) {
        let str = converter.makeHtml(options.text);
        // Remove the surrounding <p> tags for cleaner rendering
        str = str.replace(/^<p>/, "").replace(/<\/p>$/, "");
        options.html = str;
      });
      this.survey.allowHtml = true;
      this.survey.css = myCss;

      this.survey.onValueChanged.add((sender, options) =>
        this.validateField(sender, options)
      );

      this.survey.onComplete.add((sender) => {
        this.finishSurvey(); // Manually trigger finishSurvey() when survey completes
      });

      // ✅ Render the survey
      SurveyNG.render("surveyElement", { model: this.survey });
    } catch (error) {
      console.error("❌ Error fetching new survey:", error);
    }
  }
  extractAllQuestions(surveyJSON) {
    let allQuestions = [];

    if (!surveyJSON || !surveyJSON.pages) {
      console.error("❌ surveyJSON is undefined or missing 'pages' property.");
      return [];
    }

    surveyJSON.pages.forEach((pageGroup) => {
      pageGroup.forEach((page) => {
        if (!page.questions || !Array.isArray(page.questions)) {
          console.warn(`⚠️ Page ${page.id} has no valid questions.`);
          return;
        }
        page.questions.forEach((question) => {
          allQuestions.push({
            id: question.id,
            name: question.name,
            type: question.type, // ✅ Store type dynamically
          });
        });
      });
    });

    console.log("✅ Extracted Questions with Types:", allQuestions);
    return allQuestions;
  }

  extractQuestionsFromSurvey(surveyJSON) {
    if (!surveyJSON || !surveyJSON.pages) {
      console.error(
        "❌ surveyJSON is undefined or missing 'pages' property:",
        surveyJSON
      );
      return { pages: [] };
    }

    let extractedPages = [];

    // ✅ Sort pages by `id`
    surveyJSON.pages.sort((a, b) => a[0].id - b[0].id);

    surveyJSON.pages.forEach((pageGroup) => {
      pageGroup.forEach((page) => {
        if (!page.questions || !Array.isArray(page.questions)) {
          console.warn(`⚠️ Page ${page.id} has no valid questions.`);
          return;
        }

        // ✅ Sort questions within the page by `order_number`
        page.questions.sort((a, b) => a.order_number - b.order_number);

        // ✅ Format page properly for SurveyJS
        extractedPages.push({
          name: `page_${page.id}`,
          elements: page.questions.map((question) => {
            let rateValues = undefined;

            if (
              question.type === "rating" &&
              question.rate_min !== undefined &&
              question.rate_max !== undefined
            ) {
              rateValues = Array.from(
                { length: question.rate_max - question.rate_min + 1 },
                (_, i) => question.rate_min + i
              );
            }
            console.log(question.description);
            return {
              type: question.type,
              name: question.name || `question_${question.id}`,
              title: question.title || "Untitled Question",
              description: (question.description || ""),
              isRequired: question.is_required || false,
              visibleIf: this.formatVisibleIf(question.visible_if),
              enableHtml: true,

              // ✅ Handle choices for multiple-answer questions
              choices: question.choices
                ? question.choices.map((choice) => ({
                    value: choice.value,
                    text: choice.text,
                  }))
                : [],

              // ✅ Handle "Other" (free text) input
              hasOther: question.has_other || false,
              otherText: "Other (please specify)", // Change text as needed
              otherPlaceHolder: "Enter your response here...",

              cssClasses:
                question.type === "rating" ? { root: "sv-rating-default" } : {},

              // ✅ Dynamically set rating scale
              rateValues: rateValues,
            };
          }),
        });
      });
    });

    console.log("✅ Formatted Survey JSON for SurveyJS:", {
      pages: extractedPages,
      showProgressBar: "top",
    });

    return { pages: extractedPages, showProgressBar: "top" };
  }

  formatVisibleIf(visibleIf: string): string {
    if (!visibleIf || visibleIf.trim() === "") return ""; // No condition means always visible

    return visibleIf
      .replace(/{/g, "{") // Ensure curly braces remain
      .replace(/}/g, "}")
      .replace(/ = /g, " == ")
      .replace(/(\d+)/g, '"$1"'); // Convert numbers to strings for correct SurveyJS format
  }

  private isValidValue(value: any, integerOnly: boolean = false): boolean {
    console.log(value);
    if (value === null || value === undefined || isNaN(value)) {
      console.log(`Invalid value: ${value} (not a number or null/undefined)`);
      return false; // Ensure the value is a number and not null/undefined
    }
    if (typeof value === "string" && value.includes(",")) {
      console.log(`Invalid value: ${value} (contains a comma)`); // Explicitly log and reject commas
      return false; // Disallow commas entirely
    }
    if (integerOnly && !Number.isInteger(Number(value))) {
      console.log(`Invalid value: ${value} (not an integer)`);
      return false; // Ensure the value is an integer if required
    }
    return true;
  }
  validateField(sender, options) {
    const { name: questionName, value } = options;
    const question = sender.getQuestionByName(questionName);
    if (!question) return;

    const limits = {
      height: {
        min: 120,
        max: 250,
        message: "SURVEY.HEIGHT_RESTRICTION_ERROR",
      },
      weight: { min: 10, max: 150, message: "SURVEY.WEIGHT_RESTRICTION_ERROR" },
      waist: { min: 50, max: 150, message: "SURVEY.WAIST_RESTRICTION_ERROR" },
      hip: { min: 40, max: 200, message: "SURVEY.HIP_RESTRICTION_ERROR" },
    };

    // ✅ Numeric Validation for height, weight, waist, hip
    if (limits[questionName]) {
      const numericValue = Number(value);

      question.clearErrors(); // Clear previous errors

      if (
        isNaN(numericValue) ||
        numericValue < limits[questionName].min ||
        numericValue > limits[questionName].max
      ) {
        const errorMessage = this.translate.instant(
          limits[questionName].message
        );

        // Prevent duplicate error messages
        if (!question.errors.find((error) => error.text === errorMessage)) {
          question.addError(errorMessage);
        }

        sender.setValue(questionName, ""); // Clear invalid input
      }
    }
  }

  validateNumericField(key: string, value: number): boolean {
    if (isNaN(value)) {
      console.warn(`❌ Invalid ${key}: Not a number`);
      return false;
    }

    const limits = {
      height: { min: 120, max: 250 },
      weight: { min: 10, max: 150 },
      waist: { min: 50, max: 150 },
      hip: { min: 40, max: 200 },
    };

    if (limits[key]) {
      if (value < limits[key].min || value > limits[key].max) {
        console.warn(`❌ Invalid ${key}: ${value} (Out of range)`);
        return false;
      }
    }

    return true;
  }

  doOnCurrentPageChanged(survey) {
    document.getElementById("surveyComplete").style.display = survey.isLastPage
      ? "inline"
      : "none";
    const elements = document.getElementsByClassName("sv_complete_btn");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  async finishSurvey() {
    console.log("📤 Submitting Survey...");

    // Hide the complete button
    document.getElementById("surveyComplete").style.display = "none";

    // Get survey data
    const data = this.survey.data;
    console.log("📜 Survey Data Collected:", data);

    if (!this.questions || !Array.isArray(this.questions)) {
      console.error("❌ Error: `this.questions` is undefined or not an array");
      return;
    }

    this.userAnswers = [];
    let validationErrors = [];

    for (const key in data) {
      let question = this.questions.find((q) => q.name === key);
      if (!question) {
        console.warn(`⚠️ Question not found for key: ${key}`);
        continue;
      }

      const rawAnswer = data[key];

      // ✅ Handle multiple-choice questions (Checkbox)
      if (Array.isArray(rawAnswer)) {
        rawAnswer.forEach((answer) => {
          if (answer === "other") {
            console.log(
              `📝 Open-ended response for ${key}:`,
              data[`${key}-Comment`]
            );
            this.userAnswers.push({
              testResultID: this.testResults.id,
              questionID: question.id,
              answerID: null,
              openAnswer: data[`${key}-Comment`] || null,
            });
          } else {
            console.log(`✅ Selected answer for ${key}: ${answer}`);
            this.userAnswers.push({
              testResultID: this.testResults.id,
              questionID: question.id,
              answerID: Number(answer) || null,
              openAnswer: "",
            });
          }
        });
      } else {
        // ✅ Determine input type dynamically
        const isTextInput = question.type === "text";
        const isRating = question.type === "rating";

        // ✅ Validate numerical inputs (Height, Weight, Waist, Hip)
        if (isTextInput && key != "name") {
          console.log(`📏 Validating '${key}': ${rawAnswer}`);
          const numericValue = Number(rawAnswer);

          if (!this.validateNumericField(key, numericValue)) {
            validationErrors.push(`Invalid ${key} value: ${rawAnswer}`);
          } else {
            this.userAnswers.push({
              testResultID: this.testResults.id,
              questionID: question.id,
              answerID: null,
              openAnswer: rawAnswer,
            });
          }
        } else if (isRating) {
          console.log(
            `⭐ Storing '${key}' as openAnswer (rating string): "${String(
              rawAnswer
            )}"`
          );
          this.userAnswers.push({
            testResultID: this.testResults.id,
            questionID: question.id,
            answerID: null,
            openAnswer: String(rawAnswer),
          });
        } else {
          // ✅ Handle single-choice (radio buttons, dropdowns)
          let answerID = Number(rawAnswer);
          let openAnswer = null;

          if (isNaN(answerID)) {
            openAnswer = rawAnswer;
            answerID = null;
          }

          this.userAnswers.push({
            testResultID: this.testResults.id,
            questionID: question.id,
            answerID: answerID,
            openAnswer: openAnswer,
          });
        }
      }
    }

    console.log(
      "📤 Finalized Survey Answers (Before API Call):",
      this.userAnswers
    );

    try {
      console.log("🚀 Sending Survey Answers to API...");
      const response = await this.surveyService.SubmitSurvey(this.userAnswers);
      console.log("✅ Survey submitted successfully!", response);

      this.ngZone.run(() => {
        this.router.navigate(["/account-category/1"]);
      });
    } catch (error) {
      console.error("❌ Error submitting survey:", error);

      document.getElementById("surveyElement").innerHTML = `
        <div class="survey-error">
          <h2>⚠️ Submission Failed ⚠️</h2>
          <p>Something went wrong while submitting your survey.</p>
        </div>
      `;

      this.utils.showError(
        "SURVEY_SUBMISSION_ERROR",
        "Something went wrong while submitting."
      );
    }
  }

  translateUi() {
    if (this.translate.currentLang == "sk") {
      const targetNode = document.getElementById("surveyElement");
      const config = { attributes: true, childList: true, subtree: true };
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.target.textContent == "Other (describe)") {
            mutation.target.textContent = "Iné (popíšte)";
          }
        });
      });
      observer.observe(targetNode, config);

      let nextBtn = document.getElementsByClassName(
        "sv_next_btn"
      )[0] as HTMLInputElement;
      let prevBtn = document.getElementsByClassName(
        "sv_prev_btn"
      )[0] as HTMLInputElement;

      nextBtn.value = "Ďalej";
      prevBtn.value = "Späť";
    }
  }
}
