import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import axios from 'axios';
import setupAxiosPlugin from './axios-401.js';

if (environment.production) {
  enableProdMode();
}
axios.interceptors.request.use((config) => {
  // Add the custom header
  // config.headers['ngrok-skip-browser-warning'] = 'true';
  // config.headers['Bypass-Tunnel-Reminder'] = 'true';
  return config;
}, (error) => {
  // Handle request error
  return Promise.reject(error);
});
setupAxiosPlugin({ $axios: axios });
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
