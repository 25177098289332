import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from "@ngx-translate/core";
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private toastrService: ToastrService, private translate: TranslateService) {}

  public showSuccess(titleKey: string, messageKey: string) {
    this.translate.get(`NOTIFICATIONS.${titleKey}.TITLE`).subscribe((titleText: string) => {
      this.translate.get(`NOTIFICATIONS.${messageKey}.MESSAGE`).subscribe((messageText: string) => {
        this.toastrService.success(messageText, titleText);
      });
    });
  }

  public showInfo(titleKey: string, messageKey: string) {
    this.translate.get(`NOTIFICATIONS.${titleKey}.TITLE`).subscribe((titleText: string) => {
      this.translate.get(`NOTIFICATIONS.${messageKey}.MESSAGE`).subscribe((messageText: string) => {
        this.toastrService.info(messageText, titleText);
      });
    });
  }

  public showWarning(titleKey: string, messageKey: string) {
    this.translate.get(`NOTIFICATIONS.${titleKey}.TITLE`).subscribe((titleText: string) => {
      this.translate.get(`NOTIFICATIONS.${messageKey}.MESSAGE`).subscribe((messageText: string) => {
        this.toastrService.warning(messageText, titleText);
      });
    });
  }

  public showError(titleKey: string, messageKey: string) {
    this.translate.get(`NOTIFICATIONS.${titleKey}.TITLE`).subscribe((titleText: string) => {
      this.translate.get(`NOTIFICATIONS.${messageKey}.MESSAGE`).subscribe((messageText: string) => {
        this.toastrService.error(messageText, titleText);
      });
    });
  }
}
