import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { DashboardService } from "../api/calculations/dashboard";
import { AppComponent } from "../app.component";
import { SubjectService } from "../api/test/subject";

@Component({
  selector: "app-take-home-message",
  templateUrl: "./take-home-message.component.html",
  styleUrls: ["./take-home-message.component.css"],
})
export class TakeHomeMessageComponent implements OnInit {
  openContainer: boolean = false;
  testKitUUID: string;
  subjectId: number;
  constructor(
    public dashboardService: DashboardService,
    public app: AppComponent,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private subjectService: SubjectService,
  ) {}
  testKitID: number;
  takeHomeData: Object = {
    focusCategories: [],
    meals: [
      {
        meal: "Breakfast",
        title: "TAKE_HOME_MESSAGE.MEAL_BREAKFAST_TITLE",
        recommendations: [],
        tips: [],
      },
      {
        meal: "Lunch",
        title: "TAKE_HOME_MESSAGE.MEAL_LUNCH_TITLE",
        recommendations: [],
        tips: [],
      },
      {
        meal: "Dinner",
        title: "TAKE_HOME_MESSAGE.MEAL_DINNER_TITLE",
        recommendations: [],
        tips: [],
      },
      {
        meal: "Snacks",
        title: "TAKE_HOME_MESSAGE.MEAL_SNACKS_TITLE",
        recommendations: [],
        tips: [],
      },
      {
        meal: "Drinks",
        title: "TAKE_HOME_MESSAGE.MEAL_DRINKS_TITLE",
        recommendations: [],
        tips: [],
      },
    ],
    foodRecommendation: [],
  };

  loading: boolean = true;
  async ngOnInit() {
    this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
    await this.fetchSubjects(); // Wait for fetchSubjects to complete
    this.getTakeHomeMessage(); // Call after fetchSubjects is done
    this.loading = false;
    window.scrollTo(0, 0);
  }
  
  async fetchSubjects() {
    try {
      // Fetch subjects using the kit ID
      const response = await this.subjectService.GetSingleKit(this.testKitID);
      console.log("response.data");
      console.log(response.data);
      this.testKitUUID = response.data["uid_testkit"];

      this.subjectId = response.data["subject_id"];

     
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  }
  async getTakeHomeMessage() {
    const languageCode = this.app.selectedLanguage.split("-")[0];
    const response = await this.dashboardService.GetTakeHomeMessage(
      this.subjectId,
      this.testKitUUID,
      languageCode
    );


    response.data["generated_categories"].forEach((element) => {
      this.takeHomeData["focusCategories"].push(element.category);
    });

    response.data["generated_options"].forEach((element) => {
      let temp = {
        name: element.food_item,
        foodGroup: element.food_group,
      };
      this.takeHomeData["foodRecommendation"].push(temp);
    });

    response.data["generated_rules"].forEach((element) => {
      switch (element.show_meal_type) {
        case "Breakfast":
          this.takeHomeData["meals"][0]["recommendations"].push(
            element.recommendation
          );
          break;
        case "Lunch":
          this.takeHomeData["meals"][1]["recommendations"].push(
            element.recommendation
          );
          break;
        case "Dinner":
          this.takeHomeData["meals"][2]["recommendations"].push(
            element.recommendation
          );
          break;
        case "Snacks":
          this.takeHomeData["meals"][3]["recommendations"].push(
            element.recommendation
          );
          break;
        case "Drinks":
          this.takeHomeData["meals"][4]["recommendations"].push(
            element.recommendation
          );
          break;
      }
    });

    response.data["generated_tips"].forEach((element) => {
      switch (element.show_meal_type) {
        case "Breakfast":
          this.takeHomeData["meals"][0]["tips"].push(element.recommendation);
          break;
        case "Lunch":
          this.takeHomeData["meals"][1]["tips"].push(element.recommendation);
          break;
        case "Dinner":
          this.takeHomeData["meals"][2]["tips"].push(element.recommendation);
          break;
        case "Snacks":
          this.takeHomeData["meals"][3]["tips"].push(element.recommendation);
          break;
        case "Drinks":
          this.takeHomeData["meals"][4]["tips"].push(element.recommendation);
          break;
      }
    });
  }

  async downloadTakeHomeMessage() {
    const response = await this.dashboardService.DownloadTakeHomeMessage(
      parseInt(localStorage.getItem("s_id" + this.testKitID)),
      localStorage.getItem("k_uid" + this.testKitID),
      localStorage.getItem("language").toUpperCase(),
      this.app.partnerVersion
    );
    if (response.status == 200) {
      const dataurl = response.data["file_path"];
      window.open(dataurl, "_blank")!.focus();
    }
  }
}
