import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../api/login/authentication';
import {IDietaryIntakeDay, IDietaryIntakeItem, IFoodItem, IFoodItemCustom, IMissingFoodItem} from 'interfaces/advice';
import {DietaryService} from '../api/test/dietary';
import {UtilityService} from '../utility.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import Fuse from 'fuse.js';
import {type} from 'os';

@Component({
  selector: 'app-food-tracker',
  templateUrl: './food-tracker.component.html',
  styleUrls: ['./food-tracker.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FoodTrackerComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute,
              private dietaryService: DietaryService, private formBuilder: FormBuilder, private utils: UtilityService,
              private translate: TranslateService) {
  }

  referenceTable = '';
  foodItems: IFoodItemCustom[];
  dietaryDays: IDietaryIntakeDay[];
  title = 'Track your meals';
  // tslint:disable-next-line:max-line-length
  description = 'In order to have a more accurate view of your microbiome and understand the connection between your bacteria and your diet, we require you to track your meals for 3 days before taking the sample. After submitting your food diary you will receive an email containing the shipping address for your sample.';
  btnAddItem = 'Add item';
  btnViewDiary = 'View food diary';
  btnReference = 'Reference';
  btnExample = 'View Example';
  searchLbl = 'Search..';
  aClass = 'not-active';
  addItemText: string;
  addItemButton: string;

  foodForm = this.formBuilder.group({
    food_item: '',
    unit: '',
    quantity: '',
    time: '',
    day: ''
  });

  userAddItem = this.formBuilder.group({
    submittedItem: ''
  });

  selectedFoodItem = '';
  selectedFood: IFoodItem;

  testkitId;
  showReference = false;
  showCustomerField = false;

  async ngOnInit() {
    this.updateTable();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTable();
    });
    this.testkitId = Number(this.route.snapshot.paramMap.get('id'));
    const response = await this.dietaryService.GetFoodItem(this.testkitId);
    this.foodItems = response.data;
    this.dietaryDays = await (await this.dietaryService.GetDietaryDays(this.testkitId)).data;
    this.foodForm = new FormGroup({
      food_item: new FormControl(null, [Validators.required]),
      time: new FormControl(null, [Validators.required]),
      quantity: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      day: new FormControl(null, [Validators.required])
    });
    this.userAddItem = new FormGroup({
      submittedItem: new FormControl(null, [Validators.required])
    });

    this.addItemText = 'FOOD_TRACKER.CUSTOMER_ITEM_TEXT';
    this.addItemButton = 'FOOD_TRACKER.CUSTOMER_ITEM_BUTTON_UNCLICKED';
  }

  async OnSubmit() {
    if (!this.selectedFood || !this.selectedFood.id) {
      this.utils.showError('DIETARY_INTAKE_ITEM_ERROR', 'DIETARY_INTAKE_ITEM_ERROR');
      return;
    }
    const postDietaryItem: IDietaryIntakeItem = {
      food_item_id: this.selectedFood.id,
      dietary_day_id: this.foodForm.get('day').value,
      unit: this.foodForm.get('unit').value,
      quantity: this.foodForm.get('quantity').value,
      time: this.foodForm.get('time').value,
      custom_unit_type: false,
      kit_id: this.testkitId
    };

    if (this.checkDietaryItemEmpty(postDietaryItem)) {
      this.utils.showError('DIETARY_INTAKE_ITEM_ERROR', 'DIETARY_INTAKE_ITEM_ERROR');
      return;
    }

    try {
      console.log(postDietaryItem);
      const response = await this.dietaryService.PostDietaryIntakeItem(postDietaryItem);
      this.utils.showSuccess('DIETARY_INTAKE_SUCCESS', 'DIETARY_INTAKE_SUCCESS');
    } catch (err) {
      console.log(err.message);
      console.log(postDietaryItem);
      this.utils.showError('DIETARY_INTAKE_ERROR', 'DIETARY_INTAKE_ERROR');
    }




    this.selectedFood = null;
    this.foodForm.get('quantity').setValue(null);
    this.foodForm.get('food_item').setValue(null);

  }

  selectChangeHandler(event: any) {
    this.selectedFoodItem = event.target.value;
  }

  checkDietaryItemEmpty(item: IDietaryIntakeItem) {
    if (!item.food_item_id) {
      return true;
    }
    if (!item.dietary_day_id) {
      return true;
    }
    if (!item.time) {
      return true;
    }
    if (!item.unit) {
      return true;
    }
    if (!item.quantity) {
      return true;
    }
    return false;
  }

  toggleDropdown() {
    if (this.aClass === 'not-active') {
      this.aClass = 'active';
    } else {
      this.aClass = 'not-active';
    }
  }

  activateDropdown() {
    this.aClass = 'active';
  }

  chooseItem(selected: IFoodItem) {
    this.selectedFood = selected;
    this.foodForm.get('food_item').setValue(this.selectedFood.title);
    this.toggleDropdown();
  }

  filterFunction() {
    let input;
    let filter;
    let a;
    let i;
    input = document.getElementById('food_item');
    filter = input.value.toUpperCase();
    const div = document.getElementById('myDropdown');
    a = div.getElementsByTagName('p');
    for (i = 0; i < a.length; i++) {
      const txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = '';
      } else {
        a[i].style.display = 'none';
      }
    }
  }

    fuzzyFilterFunction() {
    let input;
    let filter;
    let a;
    let i;
    const itemList = [];
    input = document.getElementById('food_item');
    filter = input.value.toUpperCase();
    const div = document.getElementById('myDropdown');
    a = div.getElementsByTagName('p');

    for (i = 0; i < a.length; i++) {
      itemList.push({
        title: a[i].textContent || a[i].innerText,
        index: i});
      a[i].style.display = 'none';
    }
    itemList.reverse();

    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      limit: 10,
      keys: ['title']
    };
    const fuse = new Fuse(itemList, options);
    const result = fuse.search(filter);
    let resultSize = 15;
    if (result.length < 15) {
      resultSize = result.length;
    }
    for (i = 0; i < resultSize; i++) {
      a[result[i].item.index].style.display = '';
    }
  }

  updateTable() {
    this.translate.get('FOOD_TRACKER.REFERENCE_TABLE').subscribe((text: string) => {
      this.referenceTable = text;
    });
    this.translate.get('FOOD_TRACKER.EXAMPLE').subscribe((text: string) => {
      this.btnExample = text;
    });
    this.translate.get('FOOD_TRACKER.REFERENCE').subscribe((text: string) => {
      this.btnReference = text;
    });
    this.translate.get('FOOD_TRACKER.ADD_ITEM').subscribe((text: string) => {
      this.btnAddItem = text;
    });
    this.translate.get('FOOD_TRACKER.VIEW_FOOD_DIARY').subscribe((text: string) => {
      this.btnViewDiary = text;
    });
    this.translate.get('FOOD_TRACKER.SEARCH_LBL').subscribe((text: string) => {
      this.searchLbl = text;
    });
  }

  openNewTab() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/food-diary-example'])
    );

    window.open(url, '_blank');
  }

  async userSubmitItem() {
    console.log('there he is ', this.userAddItem.get('submittedItem').value);

    if (this.userAddItem.get('submittedItem').value === null) {
      this.utils.showWarning('EMPTY_USER_ITEM_WARNING', 'EMPTY_USER_ITEM_WARNING');
    } else {
      const postMissingFoodItem: IMissingFoodItem = {
        title: this.userAddItem.get('submittedItem').value
      };
      try {
        const response = await this.dietaryService.PostMissingFoodItem(postMissingFoodItem);
      } catch {
        console.log('Missing food item post Error');
      }

      this.addItemText = 'FOOD_TRACKER.CUSTOMER_ITEM_ADDED_TEXT';
      this.addItemButton = 'FOOD_TRACKER.CUSTOMER_ITEM_BUTTON_CLICKED';
      this.userAddItem.get('submittedItem').setValue(null);
    }
  }
}
