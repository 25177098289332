import { Component, OnInit, Input, NgModule } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IEyyo } from "../../../interfaces/advice";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import { DashboardService } from "../api/calculations/dashboard";
import { ActivatedRoute } from "@angular/router";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AppComponent } from "../app.component";
import { SubjectService } from "../api/test/subject";
import { ITestKit, ISubject } from "interfaces/tests";
import top35 from "../../assets/json/top35.json";

@Component({
  selector: "app-bacteria-levels",
  templateUrl: "./bacteria-levels.component.html",
  styleUrls: ["./bacteria-levels.component.css"],
})
@NgModule({
  declarations: [BacteriaLevelsComponent],
  exports: [BacteriaLevelsComponent], // Add this line
})
export class BacteriaLevelsComponent implements OnInit {
  @Input() hideSectionInPdf: boolean = false;
  constructor(
    private translate: TranslateService,
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private testKitIdService: TestKitIdService,
    public app: AppComponent,
    private subjectService: SubjectService
  ) {}

  gutHealth;
  dashBacteria: IEyyo;
  division;
  goodBacteria: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];
  bacteriaDescription: any;
  testKitID: number;
  testKitUID: string;
  bacteriaList: string;
  bacteriaJson: any[] = [];
  testKitIDString: string;
  subjectArray: ITestKit;
  subjectID: number;
  subjectName: string;
  testDate: string;
  formattedDate: string;
  fbRatio: number = 0; // Stores the F/B ratio
  fbStatus: string = "";
  loading: boolean = true;

  partnerImgSrc = "";

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
    }

    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));

    // console.log(this.dashBacteria);
    this.testKitID = this.testKitIdService.getTestKitID();
    this.testKitIDString = this.testKitID.toString();

    // this.gutHealth = this.dashBacteria.graphs.find(
    //   (x) => x.graph === "Gut Health"
    // ).value;
    this.division = BacteriaDivision;
    this.divideBacterias();

    try {
      this.getBacteriaList();
    } catch (error) {
      console.error(error);
    }

    // Printing Subject Name & date is PDF document
    const response = await this.subjectService.GetSubjectByTestkit(
      this.testKitID
    );
    this.subjectArray = response.data;
    this.subjectID = +this.subjectArray;
    const test = (await this.subjectService.GetTestKit(this.testKitID)).data;
    console.log(test);
    this.testKitUID = test.uid_testkit;
    const dateObj = new Date(test.activation_date);
    console.log("Test date unformatted: " + test.activation_date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    this.formattedDate = `${day}/${month}/${year}`;
    const response2 = (await this.subjectService.GetSubject(this.subjectID))
      .data;
    this.subjectName = response2.full_name;
    await this.fetchFbRatio();
    this.loading = false;
  }
  async fetchFbRatio() {
    try {
      const pipelineValue =
        (this.dashBacteria && this.dashBacteria.pipeline) || 1;

      this.bacteriaList = (
        await this.dashboardService.GetFullBacteriaList(
          this.testKitID,
          pipelineValue
        )
      ).data["output"];
      const response = (await this.dashboardService.getFbRatio(this.testKitID))
        .data;

      if (response && response.fb_ratio !== undefined) {
        this.fbRatio = response.fb_ratio;
        this.fbStatus = this.getFbStatus(this.fbRatio);
        console.log(this.fbStatus);
        console.log(`Fetched F/B Ratio: ${this.fbRatio}`);
      } else {
        console.warn("Invalid F/B ratio data received.");
      }
    } catch (error) {
      console.error("Failed to fetch F/B ratio:", error);
      this.fbRatio = 0; // Fallback value
      this.fbStatus = "Unknown";
    }
  }

  getCircleColor(value: number): string {
    if (value >= 1 && value <= 2) {
      return "great"; // Green for "great"
    } else {
      return "bad"; // Red for "bad"
    }
  }

  getFbStatus(value: number): string {
    if (value >= 1 && value <= 2) {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_2_DESIRABLE"
      );
    } else {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_2_NOT_DESIRABLE"
      );
    }
  }
  async getBacteriaList() {
    try {
      const pipelineValue =
        (this.dashBacteria && this.dashBacteria.pipeline) || 1;

      this.bacteriaList = (
        await this.dashboardService.GetFullBacteriaList(
          this.testKitID,
          pipelineValue
        )
      ).data["output"];

      console.log("bacteriaList");
      console.log(this.bacteriaList);

      const dataArray = this.bacteriaList.split("\n");

      const bacteriaData = dataArray
        .map((line, index) => {
          if (index === 0 || !line.trim()) {
            return null;
          }
          const columns = line.split(",");
          const bacteriaName = columns[0]
            .replace(/[_[\];]/g, " ")
            .trim()
            .replace(/\s+/g, " ");
          const count = parseInt(columns[1]);
          const percentage = parseFloat(columns[2]);
          
          if (
            percentage < 0.0005 ||
            (!top35["top35"].includes(bacteriaName.split(" ")[0]) &&
              percentage === 0)
          ) {
            return null;
          }

          return {
            bacteriaName,
            count,
            percentage,
          };
        })
        .filter((item) => item !== null);

      // Create a lowercase version of the top35 array
      const lowercasedTop35 = top35["top35"].map((bacteria) =>
        bacteria.toLowerCase()
      );
      console.log(bacteriaData);
      this.bacteriaJson = bacteriaData.sort((a, b) => {
        // Extract the genus or relevant name of the bacteria for comparison and lowercase them
        const genusA = a.bacteriaName.split(" ")[0].toLowerCase();
        const genusB = b.bacteriaName.split(" ")[0].toLowerCase();

        // Get the position of each genus in the lowercase version of top35
        const indexA = lowercasedTop35.indexOf(genusA);
        const indexB = lowercasedTop35.indexOf(genusB);

        // If both bacteria are in lowercasedTop35, sort by their position in top35
        if (indexA > -1 && indexB > -1) {
          return indexA - indexB;
        }

        // If only A is in lowercasedTop35, it should come first
        if (indexA > -1) {
          return -1;
        }

        // If only B is in lowercasedTop35, it should come first
        if (indexB > -1) {
          return 1;
        }

        // If neither is in lowercasedTop35, sort alphabetically by genus
        if (genusA < genusB) {
          return -1;
        } else if (genusA > genusB) {
          return 1;
        } else {
          return 0;
        }
      });

      // Check if "Christensenellaceae" exists, otherwise use "Christensenellaceae_R-7_group"
      const christensenellaceaeBacteria = this.bacteriaJson.find(
        (bacteria) =>
          bacteria.bacteriaName.toLowerCase() === "christensenellaceae"
      );
      const fallbackBacteria = this.bacteriaJson.find(
        (bacteria) =>
          bacteria.bacteriaName.toLowerCase() ===
          "christensenellaceae_r-7_group"
      );

      if (!christensenellaceaeBacteria && fallbackBacteria) {
        console.log("Using fallback: Christensenellaceae_R-7_group");
        // Use "Christensenellaceae_R-7_group" if "Christensenellaceae" does not exist
        this.bacteriaJson.push(fallbackBacteria);
      }
    } catch (error) {
      console.error(error);
    }
  }

  divideBacterias() {
    // Create a lowercase version of top35 for comparison
    const lowercasedTop35 = top35["top35"].map((bacteria: string) =>
      bacteria.toLowerCase()
    );

    this.dashBacteria.top35.forEach((elem) => {
      // Ensure elem.bacteria is treated as a string
      const bacteriaName = (elem.bacteria as string).toLowerCase();

      console.log(bacteriaName);

      // Check if bacteria is in the 'good' category
      if (
        Object.values(this.division.good)
          .map((name: string) => name.toLowerCase())
          .includes(bacteriaName)
      ) {
        if (
          bacteriaName === "christensenellaceae" ||
          (!lowercasedTop35.includes("christensenellaceae") &&
            bacteriaName === "christensenellaceae_r-7_group")
        ) {
          const bacteriaToUse =
            bacteriaName === "christensenellaceae"
              ? "Christensenellaceae"
              : "Christensenellaceae_R-7_group";
        } else {
          console.log("yey");
          if (lowercasedTop35.includes(bacteriaName)) {
            this.goodBacteria.push(elem);
          }
        }
      }

      // Check if bacteria is in the 'bad' category
      if (
        Object.values(this.division.bad)
          .map((name: string) => name.toLowerCase())
          .includes(bacteriaName)
      ) {
        this.badBacteria.push(elem);
      }

      // Check if bacteria is in the 'relevant' category
      if (
        Object.values(this.division.relevant)
          .map((name: string) => name.toLowerCase())
          .includes(bacteriaName)
      ) {
        this.relevantBacteria.push(elem);
      }
    });

    // Sort the good, bad, and relevant bacteria according to their order in top35
    this.goodBacteria.sort((a, b) => {
      const bacteriaA = a.bacteria.toLowerCase();
      const bacteriaB = b.bacteria.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });

    this.badBacteria.sort((a, b) => {
      const bacteriaA = a.bacteria.toLowerCase();
      const bacteriaB = b.bacteria.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });

    this.relevantBacteria.sort((a, b) => {
      const bacteriaA = a.bacteria.toLowerCase();
      const bacteriaB = b.bacteria.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });

    // Rename "Clostridium_sensu_stricto_1" to "Clostridium" after sorting
    this.relevantBacteria.forEach((elem) => {
      if (elem.bacteria === "Clostridium_sensu_stricto_1") {
        elem.bacteria = "Clostridium";
      }
    });
    this.goodBacteria.forEach((elem) => {
      if (elem.bacteria === "Christensenellaceae_R-7_group") {
        elem.bacteria = "Christensenellaceae";
      }
    });
    console.log(this.goodBacteria);
    console.log(this.relevantBacteria);
  }

  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = "");
  }

  getBacteriaLevel(bacteria) {
    console.log(bacteria);

    // Destructure the range for readability
    const [left, right] = bacteria.range;
    const value = bacteria.value;

    // Calculate diff and max_val
    const diff = ((right - left) / 70) * 15;
    const max_val = right + diff;

    // Define the raw ranges
    const ranges_left_raw = [Math.max(left - diff, 0), left];
    const ranges_middle_raw = [left, right];
    const ranges_right_raw = [right, max_val];

    // Define the corresponding segments
    const segments = [
      [0, 15], // Left segment
      [15, 85], // Middle segment
      [85, 100], // Right segment
    ];

    let val = 0;

    // Apply the value calculation logic
    if (value > ranges_right_raw[0]) {
      // Right segment calculation
      val =
        segments[2][0] +
        ((segments[2][1] - segments[2][0]) * (value - ranges_right_raw[0])) /
          (ranges_right_raw[1] - ranges_right_raw[0]);
    } else if (value > ranges_middle_raw[0]) {
      // Middle segment calculation
      val =
        segments[1][0] +
        ((segments[1][1] - segments[1][0]) * (value - ranges_middle_raw[0])) /
          (ranges_middle_raw[1] - ranges_middle_raw[0]);
    } else {
      // Left segment calculation
      if (ranges_left_raw[1] - ranges_left_raw[0] === 0) {
        val = segments[0][1]; // Avoid division by zero
      } else {
        val =
          segments[0][0] +
          ((segments[0][1] - segments[0][0]) * (value - ranges_left_raw[0])) /
            (ranges_left_raw[1] - ranges_left_raw[0]);
      }
    }

    // Clamp the value to ensure it stays within the range [0, 100]
    val = Math.max(0, Math.min(100, val));

    console.log(bacteria.bacteria);
    console.log(val);

    // Return the calculated position
    return `calc(${val}% - 15px)`;
  }

  async downloadBacList() {
    let partnerAddon = "";
    let r = 57;
    let g = 17;
    let b = 97;
    if (this.app.partnerVersion !== "") {
      partnerAddon = "-" + this.app.partnerVersion;
      r = 8;
      g = 29;
      b = 58;
    }

    let PdfImg =
      "./assets/Bacteria_List_First_" +
      this.app.selectedLanguage.toUpperCase() +
      "-1" +
      partnerAddon +
      ".png";

    const doc = new jsPDF();

    doc.addImage(
      PdfImg,
      "PNG",
      0,
      0,
      doc.internal.pageSize.width,
      doc.internal.pageSize.height
    );
    doc.setFontSize(14);
    doc.addFont("./assets/fonts/Ubuntu-Regular.ttf", "UbuntuFont", "normal");
    doc.setFont("UbuntuFont");
    let yOffset = this.app.isPatrisHealthPartner ? 150 : 80;

    doc.text(
      this.returnTranslation("WEBSITE_REDESIGN_DIMITAR.NAME_TEXT") +
        this.subjectName,
      15,
      yOffset
    );
    doc.text(
      this.returnTranslation("WEBSITE_REDESIGN_DIMITAR.ACTIVATION_DATE_TEXT") +
        this.formattedDate,
      15,
      yOffset + 7
    );
    doc.text(
      this.returnTranslation("ACCOUNT_CATEGORY.UUID") + ": " + this.testKitUID,
      15,
      yOffset + 14
    );
    doc.addPage();

    // Sort the bacteriaJson by percentage in descending order
    const sortedBacteria = this.bacteriaJson.sort(
      (a, b) => b.percentage - a.percentage
    );

    // Bacteria List to table
    const columns = {
      bacteria: this.returnTranslation("WEBSITE_REDESIGN_DIMITAR.BACTERIUM"),
      percentage: this.returnTranslation("WEBSITE_REDESIGN_DIMITAR.PERCENTAGE"),
    };

    const data = [];
    for (let i = 0; i < sortedBacteria.length; i++) {
      const row = [];
      row.push(sortedBacteria[i].bacteriaName);
      row.push(`${(sortedBacteria[i].percentage * 100).toFixed(2)}%`); // Convert to percentage and format
      data.push(row);
    }

    autoTable(doc, {
      startY: 20,
      head: [columns],
      headStyles: {
        fillColor: [r, g, b],
      },
      body: data,
      columnStyles: {
        bacteria: {
          font: "UbuntuFont",
        },
        percentage: {
          font: "UbuntuFont",
        },
      },
      styles: {
        font: "UbuntuFont",
        fontSize: 12,
        cellPadding: 5,
        overflow: "linebreak",
      },
      margin: { top: 10 },
    });
    if (this.app.isPatrisHealthPartner) {
      let PdfImgLast = "./assets/Bacteria_List_Last" + partnerAddon + ".png";
      doc.addImage(
        PdfImgLast,
        "PNG",
        0,
        0,
        doc.internal.pageSize.width,
        doc.internal.pageSize.height
      );
    }
    // Convert the document to a Blob and open in a new tab
    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the Blob URL in a new tab
    window.open(blobUrl, "_blank");
  }

  // Split every bacteria in different column.
  downloadBacCsv() {
    const locale = window.navigator.language;
    // Helper function for setting percentage denominator - either dot or comma
    function formatPercentage(value, locale) {
      const numberFormat = new Intl.NumberFormat(locale, {
        style: "decimal",
        minimumFractionDigits: 9,
        maximumFractionDigits: 9,
      });
      return numberFormat.format(value);
    }

    // Use tab character as delimiter
    const delimiter = "\t";
    const csvData = this.bacteriaList
      .split("\n")
      .slice(1)
      .map((row) => {
        if (row) {
          const [bacteria, countStr, percentageStr] = row.split(",");
          const count = parseInt(countStr);
          const percentage = parseFloat(percentageStr);
          const formattedPercentage = formatPercentage(percentage, locale);
          const [domain, phylum, classs, order, family, genus] =
            bacteria.split(";");
          if (
            count === 0 &&
            percentage === 0 &&
            !top35["top35"].includes(genus) &&
            !top35["top35"].includes(family)
          ) {
            return null;
          }
          return [
            domain,
            phylum,
            classs,
            order,
            family,
            genus,
            formattedPercentage,
          ];
        } else {
          return null;
        }
      })
      .filter((row) => row !== null);

    let rowTranslations = {
      en: [
        "Domain",
        "Phylum",
        "Class",
        "Order",
        "Family",
        "Genus", 
        "Percentage",
      ],
      nl: [
        "Rijk",
        "Stam",
        "Klasse",
        "Orde",
        "Familie",
        "Genus",
        "Percentage",
      ],
      it: [
        "Dominio",
        "Phylum",
        "Classe",
        "Ordine",
        "Famiglia",
        "Genere",
        "Percentuale",
      ],
      sk: [
        "Doména",
        "Kmeň",
        "Trieda",
        "Rad",
        "Čeľaď",
        "Rod",
        "Percento",
      ],
      de: [
        "Domäne",
        "Stamm",
        "Klasse",
        "Ordnung",
        "Familie",
        "Gattung",
        "Prozentsatz",
      ],
      es: [
        "Dominio",
        "Filo",
        "Clase",
        "Orden",
        "Familia",
        "Género",
        "Porcentaje",
      ],
    };

    // Check user-selected language and base headers on that
    const headerRow =
      rowTranslations[this.app.selectedLanguage] || rowTranslations["en"];

    const fileNameMap = {
      en: "Bacteria_list_",
      nl: "Bacterielijst_",
      it: "Elenco_batteri_",
      sk: "Zoznam_baktérií_",
      de: "Bakterienliste_",
      es: "Lista_bacterias_",
    };

    const fileName =
      fileNameMap[this.app.selectedLanguage] || fileNameMap["en"];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      headerRow.join(delimiter) +
      "\n" +
      csvData.map((row) => row.join(delimiter)).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + this.testKitID + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  returnTranslation(tl_value) {
    let output;
    this.translate.get(tl_value).subscribe((text: string) => {
      output = text;
    });
    return output;
  }
}
