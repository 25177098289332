import axios from "axios";
import { Injectable } from "@angular/core";
import {
  IUserSurveyAnswer,
  IUserSurveyAnswerText,
} from "../../../../interfaces/surveys";
import { environment } from "../../../environments/environment";

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

@Injectable()
export class SurveyService {
  constructor() {}

  async GetSurvey(categoryId: number) {
    return axios.get<string>(`/api/v1/survey/${categoryId}`, authHeaders());
  }
  async GetSurveyNew(design: string,isTest: boolean) {
    const testModeParam = isTest ? "&test_mode=true" : "";

    return axios.get<string>(
      `/api/v1/survey/survey_v2/?design=${design}${testModeParam}`,
      authHeaders()
    );
  }

  async GetSurveyById(surveyId: number) {
    return axios.get<string>(
      `/api/v1/survey/get_by_id/${surveyId}`,
      authHeaders()
    );
  }

  async SubmitSurvey(data: IUserSurveyAnswer[]) {
    return axios.post<boolean>(
      `/api/v1/user_survey_answer/`,
      data,
      authHeaders()
    );
  }

  async ClearSurvey(kitId: number) {
    return axios.delete<string>(
      `/api/v1/user_survey_answer/clear_survey/${kitId}`,
      authHeaders()
    );
  }

  async GetUserServeyAnswers(kitId: number, languageCode: string) {
    return axios.get<IUserSurveyAnswerText[]>(
      `/api/v1/user_survey_answer/survey_text/?kit_id=${kitId}&language_code=${languageCode}`,
      authHeaders()
    );
  }

  async LogRawSurveyData(kitId: number, rawData: any) {
    return axios.post(
      `/api/v1/survey/log_survey`,
      { kit_id: kitId, raw_data: rawData },
      authHeaders()
    );
  }
}
