import {
  Component,
  OnInit,
  Input,
  NgModule,
  ChangeDetectorRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IEyyo } from "../../../interfaces/advice";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import { DashboardService } from "../api/calculations/dashboard";
import { ActivatedRoute } from "@angular/router";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AppComponent } from "../app.component";
import { SubjectService } from "../api/test/subject";
import { ITestKit, ISubject } from "interfaces/tests";
import top35 from "../../assets/json/top35.json";
import Chart from "chart.js";

import { fromEvent, Subscription } from "rxjs";

@Component({
  selector: "app-gut-microbiome-index",
  templateUrl: "./gut-microbiome-index.component.html",
  styleUrls: ["./gut-microbiome-index.component.css"],
})
@NgModule({
  declarations: [GutMicrobiomeIndexComponent],
  exports: [GutMicrobiomeIndexComponent], // Add this line
})
export class GutMicrobiomeIndexComponent implements OnInit {
  @Input() hideSectionInPdf: boolean = false;
  gaugeChart: any;
  constructor(
    private translate: TranslateService,
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private testKitIdService: TestKitIdService,
    public app: AppComponent,
    private subjectService: SubjectService,
    private cdr: ChangeDetectorRef
  ) {}

  gutMicrobiomeIndexValue = 0;
  doughnutChartD: any;
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      less_great: "#4CEFBB",
      bad: "#fad408",
      aware: "#c8bfe7",
      bmi1: "#E5CEF3",
      bmi2: "#D3ADEB",
      bmi3: "#C28DE2",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      less_great: "#90DE90",
      bad: "#f299a1",
      aware: "#339d9c69",
      bmi1: "#54adb426",
      bmi2: "#54adb440",
      bmi3: "#54adb47d",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      less_great: "#85D88A",
      bad: "#f44336",
      aware: "#b2dfdb",
      bmi1: "#33ab9f26",
      bmi2: "#33ab9f40",
      bmi3: "#33ab9f7d",
    },
    astrolab: {
      normal: "#83C7B3",
      okay: "#cdad77",
      great: "#0C6C74",
      less_great: "#85D88A",
      bad: "#D35247",
      aware: "#f0ca7c",
      bmi1: "#0C6C7426",
      bmi2: "#0C6C7440",
      bmi3: "#0C6C747D",
    },
    goingguts: {
      normal: "#A212E2",
      okay: "#cfbcf2",
      great: "#05e399",
      less_great: "#4CEFBB",
      bad: "#fad408",
      aware: "#c8bfe7",
      bmi1: "#5F17D326",
      bmi2: "#5F17D340",
      bmi3: "#5F17D37D",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];

  gutHealth;
  dashBacteria: IEyyo;
  division;
  goodBacteria: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];
  bacteriaDescription: any;
  testKitID: number;
  testKitUID: string;
  bacteriaList: string;
  bacteriaJson: any[] = [];
  testKitIDString: string;
  subjectArray: ITestKit;
  subjectID: number;
  subjectName: string;
  testDate: string;
  formattedDate: string;
  fbRatio: number = 0; // Stores the F/B ratio
  fbStatus: string = "";
  loading: boolean = true;

  resizeSubscription$: Subscription;

  partnerImgSrc = "";

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
    }

    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));

    this.testKitID = this.testKitIdService.getTestKitID();
    this.testKitIDString = this.testKitID.toString();

    const response = await this.subjectService.GetSubjectByTestkit(
      this.testKitID
    );
    this.subjectArray = response.data;
    this.subjectID = +this.subjectArray;

    const test = (await this.subjectService.GetTestKit(this.testKitID)).data;
    this.testKitUID = test.uid_testkit;

    // Fetch gut microbiome index from the API
    await this.fetchGutMicrobiomeIndex(this.testKitID);

    this.resizeSubscription$ = fromEvent(window, "resize").subscribe(() => {
      this.renderGutMicrobiomeIndexGraph(this.gutMicrobiomeIndexValue);
    });
  }

  async fetchGutMicrobiomeIndex(kitId: number) {
    try {
      const response = await this.dashboardService.GetGutMicrobiomeIndex(kitId);
      if (response.data && response.data.gut_health_score !== undefined) {
        this.gutMicrobiomeIndexValue = Math.round(
          response.data.gut_health_score
        ); // Round to whole number
        console.log(this.gutMicrobiomeIndexValue);
      } else {
        console.error("Unexpected API response:", response);
        this.gutMicrobiomeIndexValue = 0; // Default in case of unexpected response
      }
    } catch (error) {
      console.error("Error fetching gut microbiome index:", error);
      this.gutMicrobiomeIndexValue = 0; // Fallback value
    }

    this.loading = false;
    this.cdr.detectChanges();
    this.renderGutMicrobiomeIndexGraph(this.gutMicrobiomeIndexValue);
  }

  getGutMicrobiomeMessage(): string {
    const index = Math.round(this.gutMicrobiomeIndexValue); // Ensure whole number
    if (index <= 17) {
      return "YOUR_GUT_MICROBIOME.GUT_MICROBIOME_BAD";
    } else if (index > 17 && index <= 34) {
      return "YOUR_GUT_MICROBIOME.GUT_MICROBIOME_BAD_1";
    } else if (index > 34 && index <= 50) {
      return "YOUR_GUT_MICROBIOME.GUT_MICROBIOME_BAD_NORMAL";
    } else if (index > 50 && index <= 67) {
      return "YOUR_GUT_MICROBIOME.GUT_MICROBIOME_NORMAL";
    } else if (index > 67 && index <= 83) {
      return "YOUR_GUT_MICROBIOME.GUT_MICROBIOME_GREAT";
    } else {
      return "YOUR_GUT_MICROBIOME.GUT_MICROBIOME_GREAT_1";
    }
  }
  renderGutMicrobiomeIndexGraph(indexValue: number) {
    const canvasDiversity = document.getElementById(
      "gutMicrobiomeIndex"
    ) as HTMLCanvasElement;

    if (!canvasDiversity) {
      console.error("Canvas element for gut microbiome graph not found.");
      return;
    }

    const canvasWidth = canvasDiversity.clientWidth || 600; // ✅ Dynamically get width
    console.log(" ");
    console.log(canvasWidth);

    // ✅ 1. Create a dynamic off-screen canvas
    const gradientCtx = canvasDiversity.getContext("2d");

    // ✅ 2. Create a dynamic gradient
    const gradient = gradientCtx.createLinearGradient(
      0,
      0,
      canvasWidth * 1.25,
      0
    ); // ✅ Dynamic width
    gradient.addColorStop(0.05, "#e74c3c"); // Red ends at 5%
    gradient.addColorStop(0.3, "#76ab89"); // Green begins at 35%
    gradient.addColorStop(0.5, "#76ab89"); // Green occupies most space
    gradient.addColorStop(0.8, "#123f3f"); // Dark teal begins at 80%
    gradient.addColorStop(1, "#123f3f"); // Dark teal ends at 100%

    const conf = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [100], // Single segment representing the full range
            value: Math.round(indexValue), // Ensure whole number is displayed
            backgroundColor: [gradient], // Apply the gradient to the gauge
            hoverBackgroundColor: [gradient],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: 80,
          color: "rgba(0, 0, 0, 1)", // Black needle
        },
        valueLabel: {
          display: false,
          formatter: () => `${Math.round(indexValue)}`, // Display the rounded index value
        },
        layout: {
          padding: {
            top: 40, // Extra space for labels
            bottom: 40,
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: () => `Gut Microbiome Index: ${Math.round(indexValue)}`,
            },
          },
        },
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            const { width, height, ctx, chartArea } = chart;
            if (!chartArea) return;

            const centerX = width / 2;
               const centerY = height - 35;
            const radius = (chartArea.right - chartArea.left) / 2.6;

            const labels = ["0%", "17%", "34%", "50%", "67%", "83%", "100%"];
            labels.forEach((label, i) => {
              const angle = Math.PI * (i / (labels.length - 1)) - Math.PI;
              const x = centerX + Math.cos(angle) * radius;
              const y = centerY + Math.sin(angle) * radius;

              ctx.font = "14px Arial";
              ctx.fillStyle = "#000";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(label, x, y);
            });
          },
        },
      ],
    };

    if (this.doughnutChartD) {
      this.doughnutChartD.destroy(); // Destroy the existing chart if it exists
    }

    this.doughnutChartD = new Chart(gradientCtx, conf);
  }
  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
}
