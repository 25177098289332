import { Component, OnInit, Input } from "@angular/core";
import * as Survey from "survey-angular";
import { SurveyService } from "../api/survey/survey";
import { ActivatedRoute } from "@angular/router";
import { SubjectService } from "../api/test/subject";
import { IUserSurveyAnswerText } from "../../../interfaces/surveys";
import { AppComponent } from "../app.component";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "survey-answers",
  templateUrl: "./survey-answers.component.html",
  styleUrls: ["./survey-answers.component.css"],
})
export class SurveyAnswersComponent implements OnInit {
  @Input() questionIDs: number[] | null = null; // Input to accept optional question IDs
  @Input() containerbStyles: any = {}; // Styles for the container
  @Input() tableStyles: any = {};
  @Input() age: number | null = null; // Optional age input
  @Input() additionalQuestions: {
    question_text: string;
    answer_text: string;
  }[] = []; // Additional questions

  userSurveyAnswers: IUserSurveyAnswerText[];
  testKitID: number;
  testKitStatus: string = "finished";

  constructor(
    private surveyService: SurveyService,
    private testkitService: SubjectService,
    private route: ActivatedRoute,
    public app: AppComponent,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    try {
      this.testKitID = Number(this.route.snapshot.paramMap.get("id"));

      let fetchedAnswers = [];

      // Fetch survey answers only if questionIDs is provided

      console.log(this.additionalQuestions);
      console.log(this.additionalQuestions);
      if (!(this.additionalQuestions && this.additionalQuestions.length > 0)) {
        const languageCode = this.app.selectedLanguage.split("-")[0]; 
        fetchedAnswers = (
          await this.surveyService.GetUserServeyAnswers(this.testKitID,languageCode)
        ).data;
      }
console.log(fetchedAnswers);
      // Pass the optional questionIDs and additionalQuestions to the mergeAnswers method
      this.userSurveyAnswers = this.mergeAnswers(
        fetchedAnswers,
        this.questionIDs,
        this.age,
        this.additionalQuestions
      );

      const testKit = (await this.testkitService.GetTestKit(this.testKitID))
        .data;
      this.testKitStatus = testKit.status;
    } catch {
      console.log("Error fetching survey answers or test kit");
    }
  }

  mergeAnswers(
    answers: IUserSurveyAnswerText[],
    questionIDs?: number[],
    age?: number,
    additionalQuestions?: { question_text: string; answer_text: string }[]
  ): IUserSurveyAnswerText[] {
    let uniqueQuestions: number[] = [];
    let filteredUserAnswers: IUserSurveyAnswerText[] = [];
    let duplicateUserAnswers: IUserSurveyAnswerText[] = [];
    answers.forEach((answer) => {
      const lowerCaseText = answer.question_text.toLowerCase();
      if (
        ["height", "hip", "waist"].some((field) =>
          lowerCaseText.includes(field)
        )
      ) {
        if (
          answer.answer_text &&
          (answer.answer_text.includes(",") || answer.answer_text.includes("."))
        ) {
          const numericValue = parseFloat(answer.answer_text.replace(",", "."));
          if (!isNaN(numericValue)) {
            answer.answer_text = Math.round(numericValue).toString();
          }
        }
        if (
          answer.openAnswer &&
          (answer.openAnswer.includes(",") || answer.openAnswer.includes("."))
        ) {
          const numericValue = parseFloat(answer.openAnswer.replace(",", "."));
          if (!isNaN(numericValue)) {
            answer.openAnswer = Math.round(numericValue).toString();
          }
        }
      }
    });
    // If questionIDs is provided, filter the answers to only include those question IDs
    if (questionIDs && questionIDs.length > 0) {
      answers = answers.filter((answer) =>
        questionIDs.includes(answer.question_id)
      );
    }

    let ageText = "";
    this.translate
      .get("SURVEY_ANSWERS.AGE_QUESTION")
      .subscribe((text: string) => {
        ageText = text;
      });

    // Add age as a pseudo-question if provided
    if (age !== null) {
      answers.push({
        question_id: -1, // Use a special ID for age
        question_text: ageText,
        answer_text: age.toString(),
        openAnswer: null,
      });
    }
    // Add additional questions to the answers list
    if (additionalQuestions && additionalQuestions.length > 0) {
      additionalQuestions.forEach((question, index) => {
        filteredUserAnswers.push({
          question_id: -(index + 2), // Use negative IDs for these special questions
          question_text: question.question_text,
          answer_text: question.answer_text,
          openAnswer: null,
        });
      });
    }

    // First pass to collect unique questions
    for (let i = 0; i < answers.length; i++) {
      if (!uniqueQuestions.includes(answers[i].question_id)) {
        uniqueQuestions.push(answers[i].question_id);
        filteredUserAnswers.push(answers[i]);
      } else {
        duplicateUserAnswers.push(answers[i]);
      }
    }

    // Second pass to merge duplicate answers
    for (let x = 0; x < filteredUserAnswers.length; x++) {
      for (let y = 0; y < duplicateUserAnswers.length; y++) {
        if (
          filteredUserAnswers[x].question_id ===
          duplicateUserAnswers[y].question_id
        ) {
          // Merge answer_text
          if (duplicateUserAnswers[y].answer_text) {
            filteredUserAnswers[x].answer_text = filteredUserAnswers[x]
              .answer_text
              ? filteredUserAnswers[x].answer_text.concat(
                  ", ",
                  duplicateUserAnswers[y].answer_text
                )
              : duplicateUserAnswers[y].answer_text;
          }
          // Merge openAnswer
          if (duplicateUserAnswers[y].openAnswer) {
            filteredUserAnswers[x].answer_text = filteredUserAnswers[x]
              .answer_text
              ? filteredUserAnswers[x].answer_text.concat(
                  ", ",
                  duplicateUserAnswers[y].openAnswer
                )
              : duplicateUserAnswers[y].openAnswer;
          }
        }
      }
      // After merging, sort the answers alphabetically
      if (filteredUserAnswers[x].answer_text) {
        const sortedAnswers = filteredUserAnswers[x].answer_text
          .split(", ") // Split the string into an array
          .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
          .join(", "); // Join back into a string
        filteredUserAnswers[x].answer_text = sortedAnswers;
      }
    }

    // Ensure the age question is always the second item
    const ageQuestionIndex = filteredUserAnswers.findIndex(
      (answer) => answer.question_id === -1
    );
    if (ageQuestionIndex > 0) {
      const ageQuestion = filteredUserAnswers.splice(ageQuestionIndex, 1)[0]; // Remove the age question
      filteredUserAnswers.splice(1, 0, ageQuestion); // Insert it at the second position
    }

    return filteredUserAnswers;
  }
}
