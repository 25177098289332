import {
  Component,
  OnInit,
  Input,
  NgModule,
  ChangeDetectorRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IEyyo } from "../../../interfaces/advice";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import { DashboardService } from "../api/calculations/dashboard";
import { ActivatedRoute } from "@angular/router";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AppComponent } from "../app.component";
import { SubjectService } from "../api/test/subject";
import { ITestKit, ISubject } from "interfaces/tests";
import top35 from "../../assets/json/top35.json";
import { NgZone } from "@angular/core";
import Chart from "chart.js";

import { fromEvent, Subscription } from "rxjs";

@Component({
  selector: "app-fb-index",
  templateUrl: "./fb-index.component.html",
  styleUrls: ["./fb-index.component.css"],
})
@NgModule({
  declarations: [FbIndexComponent],
  exports: [FbIndexComponent], // Add this line
})
export class FbIndexComponent implements OnInit {
  @Input() hideSectionInPdf: boolean = false;
  gaugeChart: any;
  constructor(
    private translate: TranslateService,
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private testKitIdService: TestKitIdService,
    public app: AppComponent,
    private subjectService: SubjectService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  gutMicrobiomeIndexValue = 0;
  doughnutChartD: any;
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      less_great: "#4CEFBB",
      bad: "#fad408",
      aware: "#c8bfe7",
      bmi1: "#E5CEF3",
      bmi2: "#D3ADEB",
      bmi3: "#C28DE2",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      less_great: "#90DE90",
      bad: "#f299a1",
      aware: "#339d9c69",
      bmi1: "#54adb426",
      bmi2: "#54adb440",
      bmi3: "#54adb47d",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      less_great: "#85D88A",
      bad: "#f44336",
      aware: "#b2dfdb",
      bmi1: "#33ab9f26",
      bmi2: "#33ab9f40",
      bmi3: "#33ab9f7d",
    },
    astrolab: {
      normal: "#83C7B3",
      okay: "#cdad77",
      great: "#0C6C74",
      less_great: "#85D88A",
      bad: "#D35247",
      aware: "#f0ca7c",
      bmi1: "#0C6C7426",
      bmi2: "#0C6C7440",
      bmi3: "#0C6C747D",
    },
    goingguts: {
      normal: "#A212E2",
      okay: "#cfbcf2",
      great: "#05e399",
      less_great: "#4CEFBB",
      bad: "#fad408",
      aware: "#c8bfe7",
      bmi1: "#5F17D326",
      bmi2: "#5F17D340",
      bmi3: "#5F17D37D",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];

  gutHealth;
  dashBacteria: IEyyo;
  division;
  goodBacteria: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];
  bacteriaDescription: any;
  testKitID: number;
  testKitUID: string;
  bacteriaList: string;
  bacteriaJson: any[] = [];
  testKitIDString: string;
  subjectArray: ITestKit;
  subjectID: number;
  subjectName: string;
  testDate: string;
  formattedDate: string;
  fbRatio: number = 0; // Stores the F/B ratio
  fbStatus: string = "";
  loading: boolean = true;

  resizeSubscription$: Subscription;

  partnerImgSrc = "";

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
    }

    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));

    this.testKitID = this.testKitIdService.getTestKitID();
    this.testKitIDString = this.testKitID.toString();

    const response = await this.subjectService.GetSubjectByTestkit(
      this.testKitID
    );
    this.subjectArray = response.data;
    this.subjectID = +this.subjectArray;

    const test = (await this.subjectService.GetTestKit(this.testKitID)).data;
    this.testKitUID = test.uid_testkit;
    await this.fetchFbRatio();

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.renderGutMicrobiomeIndexGraph(this.fbRatio);
      });
    });
    this.loading = false;
  }
  getFbRatioLevel(): string {
    if (!this.fbRatio) return "0%";

    const ranges = [1, 2, 3];

    for (let i = 0; i < ranges.length; i++) {
      if (this.fbRatio <= ranges[i]) {
        const position = (i + this.fbRatio / ranges[i]) * (100 / ranges.length);
        return `calc(${position}% - 2px)`;
      }
    }

    return "calc(100% - 15px)";
  }
  async fetchFbRatio() {
    try {
      const pipelineValue =
        (this.dashBacteria && this.dashBacteria.pipeline) || 1;

      this.bacteriaList = (
        await this.dashboardService.GetFullBacteriaList(
          this.testKitID,
          pipelineValue
        )
      ).data["output"];
      const response = (await this.dashboardService.getFbRatio(this.testKitID))
        .data;

      if (response && response.fb_ratio !== undefined) {
        this.fbRatio = response.fb_ratio;
        this.fbStatus = this.getFbStatus(this.fbRatio);
        console.log(this.fbStatus);
        console.log(`Fetched F/B Ratio: ${this.fbRatio}`);
      } else {
        console.warn("Invalid F/B ratio data received.");
      }
    } catch (error) {
      console.error("Failed to fetch F/B ratio:", error);
      this.fbRatio = 0; // Fallback value
      this.fbStatus = "Unknown";
    }
  }
  getCircleColor(value: number): string {
    if (value >= 1 && value <= 2) {
      return "great"; // Green for "great"
    } else {
      return "bad"; // Red for "bad"
    }
  }

  getFbStatus(value: number): string {
    if (value >= 1 && value <= 2) {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_2_DESIRABLE"
      );
    } else {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_2_NOT_DESIRABLE"
      );
    }
  }

  renderGutMicrobiomeIndexGraph(indexValue: number) {
    indexValue = Math.min(indexValue, 3);
    const canvasDiversity = document.getElementById(
      "fbIndex"
    ) as HTMLCanvasElement;

    if (!canvasDiversity) {
      console.error("Canvas element for gut microbiome graph not found.");
      return;
    }

    const canvasWidth = canvasDiversity.clientWidth || 600;
    const gradientCtx = canvasDiversity.getContext("2d");

    const gradient = gradientCtx.createLinearGradient(
      0,
      0,
      canvasWidth * 1.25,
      0
    );
    gradient.addColorStop(0.05, "#e74c3c");
    gradient.addColorStop(0.35, "#123f3f");
    gradient.addColorStop(0.4, "#123f3f");
    gradient.addColorStop(0.9, "#e74c3c");
    gradient.addColorStop(1, "#e74c3c");

    const scaledValue = (indexValue / 3) * 100; // Map 0–3 to 0–100%

    const conf = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [100],
            value: Math.round(scaledValue),
            backgroundColor: [gradient],
            hoverBackgroundColor: [gradient],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: 80,
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: () => `${indexValue.toFixed(1)}`,
        },
        layout: {
          padding: {
            top: 40,
            bottom: 40,
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: () => `F/B Ratio: ${indexValue.toFixed(2)}`,
            },
          },
        },
      },
      plugins: [
        {
          beforeDraw: (chart) => {
            const { width, height, ctx, chartArea } = chart;
            if (!chartArea) return;

            const centerX = width / 2;
            const centerY = height - 35;
            const radius = (chartArea.right - chartArea.left) / 2.6;

            const labels = ["0", "0.5", "1", "1.5", "2", "2.5", "3"];
            labels.forEach((label, i) => {
              const angle = Math.PI * (i / (labels.length - 1)) - Math.PI;
              const x = centerX + Math.cos(angle) * radius;
              const y = centerY + Math.sin(angle) * radius;

              ctx.font = "14px Arial";
              ctx.fillStyle = "#000";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(label, x, y);
            });
          },
        },
      ],
    };

    if (this.doughnutChartD) {
      this.doughnutChartD.destroy();
    }

    this.doughnutChartD = new Chart(gradientCtx, conf);
  }

  ngOnDestroy() {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }
}
