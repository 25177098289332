import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms"; // Import Validators
import { ISubject } from "interfaces/tests";
import { SubjectService } from "../api/test/subject";
import { ActivatedRoute } from "@angular/router";
import { UtilityService } from "../utility.service";
import * as countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import nlLocale from "i18n-iso-countries/langs/nl.json";
import deLocale from "i18n-iso-countries/langs/de.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import skLocale from "i18n-iso-countries/langs/sk.json";
import esLocale from "i18n-iso-countries/langs/es.json";

@Component({
  selector: "app-subject-details",
  templateUrl: "./subject-details.component.html",
  styleUrls: ["./subject-details.component.css"],
})
export class SubjectDetailsComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private subjectService: SubjectService,
    private utils: UtilityService,
    private activatedRoute: ActivatedRoute
  ) {}

  title = "Profile photo";
  subject: ISubject;
  private sub: any;
  id: number;

  // Update form group to include first_name and last_name
  subjectDetailsForm = this.formBuilder.group({
    first_name: ["", Validators.required],
    last_name: ["", Validators.required],
    date_of_birth: ["", Validators.required],
    country_origin: ["", Validators.required], // Added country field
  });
  countryNames: string[] = [];

  async ngOnInit() {
    // Fetch subject ID from route parameters
    this.sub = this.activatedRoute.params.subscribe((params) => {
      this.id = params.id;
    });

    // Fetch subject data and initialize the form
    const response = await this.subjectService.GetSubject(this.id);
    this.subject = response.data;

    // Split full_name into first_name and last_name
    const nameParts = this.subject.full_name.split(" ");
    this.subject.first_name = nameParts[0];
    this.subject.last_name = nameParts.slice(1).join(" ");

    countries.registerLocale(enLocale);
    countries.registerLocale(nlLocale);
    countries.registerLocale(deLocale);
    countries.registerLocale(itLocale);
    countries.registerLocale(skLocale);
    countries.registerLocale(esLocale);
    const countryList = countries.getNames("en"); // or use your app's selected language
    this.countryNames = Object.values(countryList); // convert object to array
    const selectedCountryCode = this.subject.country_origin;
console.log(selectedCountryCode);
    // Initialize form values
    this.subjectDetailsForm.get("first_name").setValue(this.subject.first_name);
    this.subjectDetailsForm.get("last_name").setValue(this.subject.last_name);

    this.subjectDetailsForm
      .get("date_of_birth")
      .setValue(this.subject.date_of_birth);
    localStorage.setItem("date_of_birth", this.subject.date_of_birth);
    this.subjectDetailsForm
      .get("country_origin")
      .setValue(selectedCountryCode);
  }

  async onSubmit() {
    if (this.subjectDetailsForm.invalid) {
      this.utils.showWarning(
        "PAYMENT_USER_REGISTRATION_ERROR",
        "PAYMENT_USER_REGISTRATION_ERROR"
      );
      return;
    }

    // Combine first_name and last_name into full_name
    const fullName = `${this.subjectDetailsForm.get("first_name").value} ${
      this.subjectDetailsForm.get("last_name").value
    }`.trim();

    const updatedSubject: ISubject = {
      id: this.id,
      full_name: fullName,
      date_of_birth: this.subjectDetailsForm.get("date_of_birth").value,
      country_origin: this.subjectDetailsForm.get("country_origin").value,
    };

    try {
      await this.subjectService.UpdateSubject(updatedSubject);
      this.utils.showSuccess(
        "UPDATE_USER_SUBJECT_SUCCESS",
        "UPDATE_USER_SUBJECT_SUCCESS"
      );
    } catch (error) {
      this.utils.showError(
        "UPDATE_USER_SUBJECT_ERROR",
        "UPDATE_USER_SUBJECT_ERROR"
      );
    }
  }
}
