import axios from 'axios';
import { Injectable } from '@angular/core';
import {IDietaryIntakeDay, IDietaryIntakeItem, IFoodItem, IMissingFoodItem, INutrient} from 'interfaces/advice';
import {environment} from '../../../environments/environment';

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
    const token = localStorage.getItem('token');
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

@Injectable()
export class DietaryService {
    constructor() {}

    // TODO: change this to work with testkit_id not subject_id
    async GetDietaryDays(testkitId: number) {
        return axios.get<IDietaryIntakeDay[]>(`/api/v1/dietary_intake_day/${testkitId}`, authHeaders());
    }

    async DeleteDietaryIntakeItem(testkitId: number) {
        return axios.delete<any>(`/api/v1/dietary_intake_item/${testkitId}`, authHeaders());
    }

    async GetFoodItem() {
        return axios.get<IFoodItem[]>(`/api/v1/food_item/`, authHeaders());
    }

    async GetFoodItemMeasurements(foodItemId: number) {
        return axios.get(`/api/v1/food_item/measurement/${foodItemId}`, authHeaders());
    }

    async GetFoodItemRecommendedAmount(subjectId: number, foodItemId: number) {
      return axios.get(`/api/v1/food_item/recommended-amount/${foodItemId}/${subjectId}`, authHeaders());
    }

    async GetFoodItemNutrients(foodItemId: number) {
      return axios.get<INutrient[]>(`/api/v1/nutrient_fooditem_connections/${foodItemId}`, authHeaders());
    }

    async PostMissingFoodItem(data: IMissingFoodItem) {
      return axios.post(`/api/v1/missing_food_item/`, data, authHeaders());
    }

    async PostDietaryIntakeItem(data: IDietaryIntakeItem) {
      return axios.post(`/api/v1/dietary_intake_item/`, data, authHeaders());
    }

    async UpdateDietaryIntakeItem(data: IDietaryIntakeItem, itemId: number) {
      return axios.put(`/api/v1/dietary_intake_item/${itemId}`, data, authHeaders());
    }
}
