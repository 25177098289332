import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { concat } from "rxjs";
import { ITestKitUpdate, IUserProfile } from "../../../interfaces";
import { ISubject } from "../../../interfaces/tests";
import { UserService } from "../api/users/users";
import { SubjectService } from "../api/test/subject";
import { UtilityService } from "../utility.service";
import { AppComponent } from "../app.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-human-results",
  templateUrl: "./human-results.component.html",
  styleUrls: ["./human-results.component.css"],
})
export class HumanResultsComponent implements OnInit {
  constructor(
    private subjectService: SubjectService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private translate: TranslateService,
    public app: AppComponent,
    private http: HttpClient
  ) {}

  testSubjects: ISubject[];
  categoryId: number;
  userToken: string;
  private sub: any;
  addKitForms: FormGroup;
  kitsToCompare: any = [];
  currentPage: number = 1;
  itemsPerPage: number = 30;
  totalItems: number;
  visiblePages: number | string;
  isCurrentUserAdmin: boolean;
  isHcpUser: boolean;
  isLoading = false;
  whitelist: string[] = []; // Stores whitelist emails

  async ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.categoryId = params.id;
    });

    this.userToken = this.readToken();
    if (this.userToken != "") {
      localStorage.setItem("token", this.userToken);
    } else if (localStorage.getItem("token") == null) {
      this.router.navigate(["vysledky"]);
    }
    const response = await this.userService.GetUser();

    this.isCurrentUserAdmin = response.data.is_superuser;
    this.isHcpUser = response.data.is_hcp_user;
    console.log(response);

    this.addKitForms = new FormGroup({
      kit_code: new FormControl(null, [Validators.required]),
    });

    await this.loadSubjects();
  }

  
  async loadSubjects() {
    const skip = (this.currentPage - 1) * this.itemsPerPage;
    try {
      const response = await this.subjectService.GetSubjects(
        undefined,
        undefined,
        undefined,
        skip
      );
      this.testSubjects = response.data["results"] || [];
      console.log(response.data);
      this.totalItems = response.data["total_rows"] || this.totalItems;
      this.updatePagination();
    } catch (error) {
      console.error("Error fetching subjects:", error);
      this.testSubjects = [];
    } finally {
      // Ensure loading is set to false
      this.isLoading = false;
    }
  }

  async onPageChange(
    page: number,
    name: string = "",
    kitId: string = "",
    kitUid: string = ""
  ) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.isLoading = true; // Show loading spinner

      // Calculate the offset for pagination
      const skip = (this.currentPage - 1) * this.itemsPerPage;

      try {
        const response = await this.subjectService.GetSubjects(
          name || undefined,
          kitId || undefined,
          kitUid || undefined,
          skip
        );

        this.testSubjects = response.data["results"] || [];
        console.log("Page Subjects:", this.testSubjects);
      } catch (error) {
        console.error("Error fetching subjects for page:", error);
        this.testSubjects = [];
      } finally {
        this.isLoading = false; // Hide loading spinner
      }
    }
  }

  updatePagination() {
    const totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.visiblePages = totalPages;
  }

  async applyFilters(name: string, kitId: string, kitUid: string) {
    console.log("Applying filters:", { name, kitId, kitUid });
    this.isLoading = true; // Show loading spinner
    this.currentPage = 1; // Reset to the first page for filtered results

    try {
      // Fetch filtered results
      const response = await this.subjectService.GetSubjects(
        name || undefined,
        kitId || undefined,
        kitUid || undefined,
        0 // Always start from the first page for new filters
      );

      // Update testSubjects with filtered results
      this.testSubjects = response.data["results"] || [];
      this.totalItems = response.data["total_rows"] || 0; // Update total items based on filtered results
      console.log("Filtered Subjects:", this.testSubjects);

      // Update pagination based on filtered results
      this.updatePagination();
    } catch (error) {
      console.error("Error fetching filtered subjects:", error);
      this.testSubjects = []; // Clear results if an error occurs
      this.totalItems = 0; // Reset total items
    } finally {
      this.isLoading = false; // Hide loading spinner
    }
  }

  async onSubmit(subjectID: number) {
    const testkit_uid = this.addKitForms.get("kit_code").value.toUpperCase();
    const updateKitCode: ITestKitUpdate = {
      uid_testkit: testkit_uid,
      subject_id: subjectID,
    };
    try {
      const response = await this.subjectService.LinkTestKit(
        updateKitCode,
        subjectID
      );
      this.utils.showSuccess(
        "TEST_KIT_LINKED_SUCCESS",
        "TEST_KIT_LINKED_SUCCESS"
      );
    } catch {
      this.utils.showWarning(
        "TEST_KIT_NOT_FOUND_WARNING",
        "TEST_KIT_NOT_FOUND_WARNING"
      );
    }

    try {
      const rs = await this.subjectService.TryConenctingHCPKit(testkit_uid);
    } catch (err) {
      console.log("Error with HCP kit connection: " + err);
    }

    try {
      // Fetch updated subjects with potential filters applied
      const response2 = await this.subjectService.GetSubjects(
        undefined,
        undefined,
        undefined,
        0
      );
      if (response2.data["results"]) {
        this.testSubjects = response2.data["results"];
      } else {
        console.warn("No subjects returned from GetSubjects.");
      }

      // Fetch HCP-linked subjects and combine
      const hcp_response = await this.subjectService.GetHCPSubjects();
      this.testSubjects = this.testSubjects.concat(hcp_response.data);

      // Reset the form after successful operations
      this.addKitForms.reset();
      console.log("Subjects refreshed successfully:", this.testSubjects);
    } catch (err) {
      console.error("Error retrieving updated subjects or HCP kits:", err);
    }
  }

  convertDate(test_date: string | null | undefined): string {
    if (!test_date) {
      return ""; // Return an empty string if the date is null or undefined
    }
    const dateParts = test_date.split("-");
    return dateParts[2].concat("/", dateParts[1], "/", dateParts[0]);
  }

  readToken(): string {
    const name = "token=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const tokenFromCookies = decodedCookie
      .split(";")
      .find((c) => c.trim().startsWith(name));

    let token = tokenFromCookies
      ? tokenFromCookies.trim().substring(name.length)
      : localStorage.getItem("token") || "";

    // Save token in localStorage for consistent retrieval
    if (token) {
      localStorage.setItem("token", token);
    }

    return token;
  }

  async toDashboard(subjectId, kit_uid, kit_id) {
    localStorage.setItem("k_uid" + kit_id, kit_uid);
    localStorage.setItem("s_id" + kit_id, subjectId);

    await this.router.navigate([this.app.activeLinks.dashboard + kit_id]);
  }
  isKitSelected(id: any) {
    let exists = this.kitsToCompare.indexOf((item: any) => item.id == id);

    if (exists != -1) {
      return true;
    }

    return false;
  }
  addKitToCompare(subject: any, kit: any) {
    console.log(subject);
    console.log(kit);
    if (this.kitsToCompare.length > 0) {
      let kitAdded = this.kitsToCompare.find(
        (item: any) => item.uid === kit.uid
      );

      if (kitAdded) {
        let kitIndex = this.kitsToCompare.indexOf(kitAdded);
        this.kitsToCompare.splice(kitIndex, 1);

        return;
      }
    }
    let temp = {
      id: kit.id,
      name: subject.full_name,
      date: kit.activation_date,
      uid: kit.uid_testkit,
    };
    this.kitsToCompare.push(temp);
  }

  async toKitComparison(subject: any) {
    if (this.kitsToCompare.length < 2 || this.kitsToCompare.length > 5) {
      this.utils.showError(
        "COMPARISSON_WRONG_NR_OF_KITS",
        "COMPARISSON_WRONG_NR_OF_KITS"
      );
      return;
    }
    if (this.kitsToCompare.length > 0) {
      sessionStorage.setItem(
        "ComparisonKits",
        JSON.stringify(this.kitsToCompare)
      );

      this.router.navigate(["kit-comparison"]);
      return;
    }
    let kitids = [];
    subject.testkits.forEach((element: any) => {
      let temp = {
        id: element.id,
        date: element.activation_date,
        uid: element.uid_testkit,
      };
      kitids.push(temp);
    });
    sessionStorage.setItem("ComparisonKits", JSON.stringify(kitids));

    this.router.navigate(["kit-comparison"]);
  }
  async sendSampleReceivedEmail(kitId: number) {
    try {
      this.isLoading = true; // Show loading state
      
      await this.subjectService.SendSampleReceivedEmail(kitId, this.app.partnerVersion);

  
      // Update UI after email is sent
      const subject = this.testSubjects.find((s) => 
        s.testkits.some((kit) => kit.id === kitId)
      );
      
      const kit = subject ? subject.testkits.find((kit) => kit.id === kitId) : undefined;
      
      if (kit) {
        kit.sample_received_email = true; // Mark email as sent
      }
  
      this.utils.showSuccess("SAMPLE_EMAIL_SENT_SUCCESS", "SAMPLE_EMAIL_SENT_SUCCESS");
    } catch (error) {
      console.error("Error sending email:", error);
      this.utils.showError("SAMPLE_EMAIL_SENT_FAILED", "SAMPLE_EMAIL_SENT_FAILED");
    } finally {
      this.isLoading = false; // Hide loading state
    }
  }
  
}