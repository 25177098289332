import { Theme } from "./symbols";

export const goinggutsTheme: Theme = {
  name: "goingguts",
  properties: {
    "--color-profile-tab": "#5F17D3", // Main theme color
    "--navbar-img": 'url("assets/goingguts-logo.png")',
    "--footer-img": 'url("assets/goingguts-logo.png")',
    "--navbar-color": "#5F17D3", // Darker shade for nav elements
    "--navbar-light-color": "#ffffff", // Light text color
    "--conditions-color": "#A212E2", // Complementary lighter tone
    "--navigation-color": "#A212E2", // Matching conditions color
    "--contact-color": "#A212E2", // Accent for contact areas
    "--copyrights-color": "#5F17D3", // Secondary accent for text
    "--btn-dark-color": "linear-gradient(135deg, #A212E2 0%, #5F17D3 100%)", // Gradient with main and accent colors
    "--btn-light-color": "#A212E2", // Semi-transparent light purple
    "--btn-light-color-active": "#A212E2", // Full opacity of light purple
    "--table-header-color": "#5F17D3", // Matches primary color
    "--kit-code-btn-color": "#5F17D3", // Accent color for call-to-actions
    "--btn-fill-in-color": "#A212E2", // Light complementary tone
    "--btn-fill-in-color-active": "#A212E2",
    "--food-diary-color": "#DF0DF0", // High-visibility pink for important actions
    "--food-diary-color-light": "#F5F7FA", // Neutral light background
    "--survey-color": "#5F17D3",
    "--survey-color-complete": "#A212E2", // Lighter purple for completed items
    "--dashboard-container": "#F5F7FA", // Neutral container background
    "--dashboard-container-hover": "#5F17D354", // Slightly transparent hover effect
    "--sub-dropdown-color": "#5F17D3",
    "--dropdown-box": "#5F17D326", // Very light purple for dropdowns
    "--compound-container-color": "#5F17D326", // Muted complementary color
    "--normal-color": "#A212E2",
    "--okay-color": "#cfbcf2",
    "--less-great-color": "#4CEFBB",
    "--great-color": "#05e399",
    "--bad-color": "#fad408",
    "--aware-color": "#c8bfe7",
    "--warning-color": "#fad408",
    "--warning-color-fibre": "#DF0DF0", // Same pink for fiber warnings
    "--take-home-bubble-color": "#5F17D3", // Matches main theme
    "--rectangle-color": "#5F17D326", // Light rectangle background
    "--description-color": "#F5F7FA", // Neutral text background
    "--protein-color": "#A212E2", // Matches complementary tone
    "--bmi-1-color": "#FFE55A",
    "--bmi-2-color": "#05e399",
    "--bmi-3-color": "#FFE55A",
    "--bmi-4-color": "#fad408",
    "--tg-1-color": "#5F17D326",
    "--tg-2-color": "#5F17D340",
    "--tg-3-color": "#5F17D37D",
    "--recomendations-color": "#5F17D3", // Matches main theme
    "--img-placeholder-color": "#5F17D3", // Consistent light background
    "--fibre-color": "#A212E28F", // Light semi-transparent tone
    "--green-color": "#05e399",
    "--yellow-color": "#fad408",
    "--lessnormal-color": "#A212E28F",
    "--morenormal-color": "#A212E28F",
    "--text-color": "#ffffff",
    "--footer-justify-content": "space-between",
    "--button-light-1-color": "#5F17D326",
  },
};
