import { Component, OnInit } from "@angular/core";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { DashboardService } from "../api/calculations/dashboard";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "../app.component";
import { SurveyService } from "../api/survey/survey";

@Component({
  selector: "app-bmi",
  templateUrl: "./bmi.component.html",
  styleUrls: ["./bmi.component.css"],
})
export class BmiComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    public app: AppComponent,
    private router: Router,
    private translate: TranslateService
  ) {}
  bmiValue: any;
  testKitID;
  importantSurveyQuestions = [0];
  additionalQuestions = [
  ];
  loading: boolean = true;
  partnerImgSrc = "";

  newSurvey: boolean = false;
  hipWaistRanges: Array<{
    label: string;
    min: number;
    max: number;
    className: string;
  }>;
  hipWaistValue: number;
  isFemale: boolean;
  async ngOnInit() {
    try {
      if (this.app.partnerVersion != "") {
        this.partnerImgSrc = "-" + this.app.partnerVersion;
      }
      this.testKitID = Number(this.route.snapshot.paramMap.get("id"));

      const measurements = await this.dashboardService.GetMeasurements(
        this.testKitID
      );
      console.log(measurements)

      // Extract BMI and Hip/Waist values using bracket notation
      this.bmiValue =
        measurements.data && measurements.data["bmi"]
          ? measurements.data["bmi"]
          : null; // Use null if BMI is not available
      this.hipWaistValue =
        measurements.data && measurements.data["hip_waist_ratio"]
          ? measurements.data["hip_waist_ratio"]
          : null;

      console.log("Fetched BMI Value:", this.bmiValue);
      console.log("Fetched Hip/Waist Value:", this.hipWaistValue);

      this.hipWaistRanges = this.isFemale
        ? [
            {
              label: this.translate.instant("BMI.LOW_RISK"),
              min: 0,
              max: 0.79,
              className: "bmi-2",
            },
            {
              label: this.translate.instant("BMI.MODERATE_RISK"),
              min: 0.8,
              max: 0.84,
              className: "bmi-1",
            },
            {
              label: this.translate.instant("BMI.HIGH_RISK"),
              min: 0.85,
              max: 10,
              className: "bmi-4",
            },
          ]
        : [
            {
              label: this.translate.instant("BMI.LOW_RISK"),
              min: 0,
              max: 0.95,
              className: "bmi-2",
            },
            {
              label: this.translate.instant("BMI.MODERATE_RISK"),
              min: 0.96,
              max: 0.99,
              className: "bmi-1",
            },
            {
              label: this.translate.instant("BMI.HIGH_RISK"),
              min: 1.0,
              max: 10,
              className: "bmi-4",
            },
          ];
     
          this.additionalQuestions = [
            {
              question_text: this.translate.instant("BMI.QUESTION_HEIGHT"),
              answer_text: measurements.data["height"] ? measurements.data["height"].toString() : "N/A", // Handle null values
            },
            {
              question_text: this.translate.instant("BMI.QUESTION_WEIGHT"),
              answer_text: measurements.data["weight"] ? measurements.data["weight"].toString() : "N/A", // Handle null values
            },
            {
              question_text: this.translate.instant("BMI.QUESTION_WAIST"),
              answer_text: measurements.data["waist"] ? measurements.data["waist"].toString() : "N/A", // Handle null values
            },
            {
              question_text: this.translate.instant("BMI.QUESTION_HIP"),
              answer_text: measurements.data["hip"] ? measurements.data["hip"].toString() : "N/A", // Handle null values
            },
          ].filter((q) => q.answer_text !== "N/A"); // Filter out answers marked as "N/A"
          
    
   
      await this.fetchAndLogSurveyAnswers();
    } catch (error) {
      this.bmiValue = null;
      this.hipWaistValue = null;
      console.error("Error during initialization:", error);
    } finally {
      // Ensure loading is set to false after all data is fetched
    }
  }
  getHipWaistLevel(): string {
    if (!this.hipWaistValue) return "0%";

    const ranges = !this.isFemale ? [0.95, 1, 1.9]:[0.80, 0.85, 1.6];

    for (let i = 0; i < ranges.length; i++) {
      if (this.hipWaistValue <= ranges[i]) {
        const position =
          (i + this.hipWaistValue / ranges[i]) * (100 / ranges.length);
        return `calc(${position}% - 15px)`;
      }
    }

    return "calc(100% - 15px)";
  }
  getBMILevel(): string {
    if (!this.bmiValue) return "0%";
  
    const bmiThresholds = [0, 18.5, 25, 30, 40]; // BMI thresholds
    const graphThresholds = [0, 25, 50, 75, 100]; // Graph positions for each range
  
    for (let i = 1; i < bmiThresholds.length; i++) {
      if (this.bmiValue <= bmiThresholds[i]) {
        const position =
          graphThresholds[i - 1] +
          ((this.bmiValue - bmiThresholds[i - 1]) /
            (bmiThresholds[i] - bmiThresholds[i - 1])) *
            (graphThresholds[i] - graphThresholds[i - 1]);
  
        console.log(`BMI Value: ${this.bmiValue}, Calculated Position: ${position}%`);
        return `calc(${position}% - 15px)`; // Adjust for needle width
      }
    }
  
    console.log(`BMI Value exceeds thresholds: ${this.bmiValue}`);
    return "calc(100% - 15px)";
  }
  

  async fetchAndLogSurveyAnswers() {
    try {
      // Replace with an appropriate kitId for your testing
      const kitId = this.testKitID; // Example kit ID, update it accordingly
      const surveyService = new SurveyService();
      const languageCode = this.app.selectedLanguage.split("-")[0]; 
      const surveyAnswers = await surveyService.GetUserServeyAnswers(kitId,languageCode);
      this.newSurvey = surveyAnswers.data.some(
        (item) => item.question_id === 275
      );

      const genderAnswer = surveyAnswers.data.find(
        (item) => item.question_id === 279
      );
      if (genderAnswer && genderAnswer.answer_text === "Female") {
        this.isFemale = true;
      } else {
        this.isFemale = false;
      }
      console.log(this.newSurvey);
      console.log("User Survey Answers:", surveyAnswers.data);
    } catch (error) {
      console.error("Error fetching user survey answers:", error);
    } finally {
      this.loading = false;
    }
  }
}
