import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  Overlay,
  OverlayContainer,
  ToastrModule,
  ToastrService,
} from "ngx-toastr";
import { TakeHomeDietAdjustmentMealComponent } from "src/components/take-home-diet-adjustment-meal/take-home-diet-adjustment-meal.component";
import { TakeHomeFoodRecomendationComponent } from "src/components/take-home-food-recomendation/take-home-food-recomendation.component";
import { TakeHomeDashboardFoodRecomendationComponent } from "../components/take-home-dashboard-food-recomendation/take-home-dashboard-food-recomendation.component";
import { TakeHomeDashboardMealComponent } from "../components/take-home-dashboard-meal/take-home-dashboard-meal.component";
import { TakeHomeFocusGroupDashboardComponent } from "../components/take-home-focus-group-dashboard/take-home-focus-group-dashboard.component";
import { TakeHomeFocusGroupPillComponent } from "../components/take-home-focus-group-pill/take-home-focus-group-pill.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { AccountCategoryComponent } from "./account-category/account-category.component";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { AccountQuestionsComponent } from "./account-questions/account-questions.component";
import { AkkermansiaComponent } from "./akkermansia/akkermansia.component";
import { AnimalsComponent } from "./animals/animals.component";
import { DashboardService } from "./api/calculations/dashboard";
import { DietaryDashService } from "./api/calculations/dietarydash";
import { AuthGuardService } from "./api/login/auth";
import { AuthenticationService } from "./api/login/authentication";
import { ItemsService } from "./api/orders/items";
import { OrdersService } from "./api/orders/orders";
import { SurveyService } from "./api/survey/survey";
import { TestsService } from "./api/test/bacteria";
import { DietaryService } from "./api/test/dietary";
import { MealPlannerService } from "./api/test/meal_planner";
import { SubjectService } from "./api/test/subject";
import { UserService } from "./api/users/users";
import { UtilsService } from "./api/utils/utils";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BacteriaDiversityComponent } from "./bacteria-diversity/bacteria-diversity.component";
import { BacteriaLevelsComponent } from "./bacteria-levels/bacteria-levels.component";
import { BifidobacteriumComponent } from "./bifidobacterium/bifidobacterium.component";
import { BmiComponent } from "./bmi/bmi.component";
import { BuyComponent } from "./buy/buy.component";
import { ColonProblemComponent } from "./colon-problem/colon-problem.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";
import { CreateAccountComponent } from "./create-account/create-account.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ExampleFoodDiaryComponent } from "./example-food-diary/example-food-diary.component";
import { ExtraOptionsComponent } from "./extra-options/extra-options.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { FatAlarmComponent } from "./fat-alarm/fat-alarm.component";
import { FilterPipe } from "./filter.pipe";
import { FoodDiaryComponent } from "./food-diary/food-diary.component";
import { FoodTrackerComponent } from "./food-tracker/food-tracker.component";
import { GasProductionComponent } from "./gas-production/gas-production.component";
import { GutHealthComponent } from "./gut-health/gut-health.component";
import { HealthAndDiseasesComponent } from "./health-and-diseases/health-and-diseases.component";
import { HealthyMicroorganismsComponent } from "./healthy-microorganisms/healthy-microorganisms.component";
import { HealthySubstancesComponent } from "./healthy-substances/healthy-substances.component";
import { HumanButtonsComponent } from "./human-buttons/human-buttons.component";
import { HumanResultsComponent } from "./human-results/human-results.component";
import { HumanComponent } from "./human/human.component";
import { InfectionAlarmComponent } from "./infection-alarm/infection-alarm.component";
import { InformationComponent } from "./information/information.component";
import { LaboratoriesComponent } from "./laboratories/laboratories.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { NewUserComponent } from "./new-user/new-user.component";
import { NotLoggedInComponent } from "./not-logged-in-page/not-logged-in-page.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { PasswordRecoveryComponent } from "./password-recovery/password-recovery.component";
import { PaymentDetailsComponent } from "./payment-details/payment-details.component";
import { PdfDashboardComponent } from "./pdf-dashboard/pdf-dashboard.component";
import { PersonalizedDietaryAdviceComponent } from "./personalized-dietary-advice/personalized-dietary-advice.component";
import { PhysicalExerciseFitnessAndGutComponent } from "./physical-exercise-fitness-and-gut/physical-exercise-fitness-and-gut.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ProcessComponent } from "./process/process.component";
import { RelationshipBetweenDietAndMicrobiomeComponent } from "./relationship-between-diet-and-microbiome/relationship-between-diet-and-microbiome.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ResultsTableComponent } from "./results-table/results-table.component";
import { RoutingModule } from "./routing/routing.module";
import { ShippingPolicyComponent } from "./shipping-policy/shipping-policy.component";
import { ShoppingCartComponent } from "./shopping-cart/shopping-cart.component";
import { SoilComponent } from "./soil/soil.component";
import { SubjectDetailsComponent } from "./subject-details/subject-details.component";
import { SuccessfulPaymentComponent } from "./successful-payment/successful-payment.component";
import { SurveyComponent } from "./survey/survey.component";
import { TakeHomeMessageComponent } from "./take-home-message/take-home-message.component";
import { TargetValuesComponent } from "./target-values/target-values.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { TestCategoryComponent } from "./test-category/test-category.component";
import { TestComponentComponent } from "./test-component/test-component.component";
import { TestResultsComponent } from "./test-results/test-results.component";
import { TestingProceduresComponent } from "./testing-procedures/testing-procedures.component";
import { TopBacteriaComponent } from "./top-bacteria/top-bacteria.component";
import { UnsubscribeEmail } from "./unsubscribe-email/unsubscribe-email.component";
import { ThemeModule, defaultTheme, iprobioTheme, patrisHealthTheme, astrolabTheme, goinggutsTheme } from "./theme";
import { KitComparisonComponent } from "./kit-comparison/kit-comparison.component";
import { ComparisonBmiComponent } from "src/components/comparison-bmi/comparison-bmi.component";
import { ComparisonDiversityComponent } from "src/components/comparison-diversity/comparison-diversity.component";
import { ComparisonBacteriaLevelsComponent } from "src/components/comparison-bacteria-levels/comparison-bacteria-levels.component";
import { ComparisonCategoriesComponent } from "src/components/comparison-categories/comparison-categories.component";
import { ComparisonTargetNutrientsComponent } from "src/components/comparison-target-nutrients/comparison-target-nutrients.component";

import { SurveyAnswersComponent } from "./survey-answers/survey-answers.component";
import { SupplementAdviceComponent } from './supplement-advice/supplement-advice.component';
import { LoadingComponent } from 'src/components/loading/loading.component';
import { ReportsComponent } from './reports/reports.component';
import { GutMicrobiomeIndexComponent } from './gut-microbiome-index/gut-microbiome-index.component';
import { FbIndexComponent } from './fb-index/fb-index.component';

@NgModule({
  declarations: [
    AppComponent,
    BuyComponent,
    TestResultsComponent,
    InformationComponent,
    AboutUsComponent,
    HumanComponent,
    HumanButtonsComponent,
    AnimalsComponent,
    SoilComponent,
    TopBacteriaComponent,
    TestCategoryComponent,
    ExtraOptionsComponent,
    CreateAccountComponent,
    AccountDetailsComponent,
    SurveyComponent,
    FoodTrackerComponent,
    ProcessComponent,
    ComparisonTargetNutrientsComponent,
    AccountQuestionsComponent,
    FoodDiaryComponent,
    AccountCategoryComponent,
    HumanResultsComponent,
    NewUserComponent,
    ShoppingCartComponent,
    PaymentDetailsComponent,
    ResultsTableComponent,
    DashboardComponent,
    TargetValuesComponent,
    PersonalizedDietaryAdviceComponent,
    BmiComponent,
    HealthyMicroorganismsComponent,
    BifidobacteriumComponent,
    FaqsComponent,
    PasswordRecoveryComponent,
    ResetPasswordComponent,
    SubjectDetailsComponent,
    OrderHistoryComponent,
    FilterPipe,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ShippingPolicyComponent,
    CookiePolicyComponent,
    ContactUsComponent,
    GutHealthComponent,
    ColonProblemComponent,
    InfectionAlarmComponent,
    GasProductionComponent,
    FatAlarmComponent,
    HealthySubstancesComponent,
    BacteriaDiversityComponent,
    BacteriaLevelsComponent,
    AkkermansiaComponent,
    TestComponentComponent,
    LandingPageComponent,
    TestingProceduresComponent,
    LaboratoriesComponent,
    HealthAndDiseasesComponent,
    RelationshipBetweenDietAndMicrobiomeComponent,
    PhysicalExerciseFitnessAndGutComponent,
    SuccessfulPaymentComponent,
    ExampleFoodDiaryComponent,
    MaintenanceComponent,
    PdfDashboardComponent,
    NotLoggedInComponent,
    UnsubscribeEmail,
    TakeHomeMessageComponent,
    TakeHomeFocusGroupPillComponent,
    TakeHomeDietAdjustmentMealComponent,
    TakeHomeFoodRecomendationComponent,
    TakeHomeDashboardMealComponent,
    TakeHomeDashboardFoodRecomendationComponent,
    TakeHomeFocusGroupDashboardComponent,
    TakeHomeFocusGroupDashboardComponent,
    KitComparisonComponent,
    ComparisonBmiComponent,
    ComparisonDiversityComponent,
    ComparisonBacteriaLevelsComponent,
    ComparisonCategoriesComponent,
    SurveyAnswersComponent,
    SupplementAdviceComponent,
    LoadingComponent,
    ReportsComponent,
    GutMicrobiomeIndexComponent,
    FbIndexComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000, // 15 seconds
      closeButton: true,
      progressBar: true,
      positionClass: "toast-bottom-right",
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RoutingModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ThemeModule.forRoot({
      themes: [defaultTheme, iprobioTheme, patrisHealthTheme, astrolabTheme, goinggutsTheme],
      active: "default",
    }),
  ],
  providers: [
    AuthenticationService,
    SubjectService,
    UserService,
    DietaryService,
    ItemsService,
    TestsService,
    OrdersService,
    SurveyService,
    MealPlannerService,
    DashboardService,
    ToastrService,
    DietaryDashService,
    Overlay,
    UtilsService,
    OverlayContainer,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    AuthGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
