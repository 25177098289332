import { Component, OnInit, Input } from "@angular/core";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import { AppComponent } from "../../app/app.component";
import top35 from "../../assets/json/top35.json";
import { IEyyo } from "interfaces/advice";

@Component({
  selector: "app-comparison-bacteria-levels",
  templateUrl: "./comparison-bacteria-levels.component.html",
  styleUrls: ["./comparison-bacteria-levels.component.css"],
})
export class ComparisonBacteriaLevelsComponent implements OnInit {
  @Input() data: any = {};

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: [
      "iprobio_blue",
      "iprobio_blue_light",
      "iprobio_green",
      "iprobio_red",
      "iprobio_blue_dark",
    ],
    patris_health: [
      "patris_health_blue",
      "patris_health_green",
      "patris_health_purple",
      "patris_health_yellow",
      "patris_health_light_purple"
    ],
    astrolab: [
      "astrolab_blue",
      "astrolab_green",
      "astrolab_yellow",
      "astrolab_red",
      "astrolab_purple",
    ],
  };
  legendColors: any = this.legendColorsVariants["default"];
  goodBacteria: any[] = [];
  goodBacteriaTEST: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];

  division: any;
  dashBacteria: IEyyo;

  bacteriaDescription: any;

  partnerImgSrc: string = "";

  constructor(public app: AppComponent) {}

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
      this.partnerImgSrc = "-" + this.app.partnerVersion;
    }
    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
    this.division = BacteriaDivision;
    this.divideBacterias();
    console.log(this.goodBacteria);
    console.log(this.badBacteria);
  }
  sharesValue(item: any, i: any, values: any) {
    let flip = false;
    values.forEach((element: any, index: any) => {
      let tempItemLevel = this.getBacteriaLevel(item).replace("%", "");
      let tempElementLevel = this.getBacteriaLevel(element).replace("%", "");

      let diff = Math.abs(
        parseFloat(tempItemLevel.replace("calc(", "").replace(")", "")) -
          parseFloat(tempElementLevel.replace("calc(", "").replace(")", ""))
      );

      if (diff < 1) {
        if (i > index) flip = true;
      }
    });

    return flip;
  }

  toggleBacteriaDescription(bacteria: string | null) {
    this.bacteriaDescription = bacteria ? bacteria : "";
  }

  divideBacterias() {
    const lowercasedTop35 = top35["top35"].map((bacteria: string) =>
      bacteria.toLowerCase()
    );
    console.log(lowercasedTop35);

    this.data.forEach((elem: any) => {
      elem.top35.forEach((element: any) => {
        let temp = {
          name: element.bacteria,
          values: [],
        };
        let tempValues = {
          date: elem.date,
          range: element.range,
          value: element.value,
          status: element.status,
        };
        temp.values.push(tempValues);
        const bacteriaName = (element.bacteria as string).toLowerCase();

        if (
          Object.values(this.division.good)
            .map((name: string) => name.toLowerCase())
            .includes(bacteriaName)
        ) {
          if (
            bacteriaName === "christensenellaceae" ||
            (!lowercasedTop35.includes("christensenellaceae") &&
              bacteriaName === "christensenellaceae_r-7_group")
          ) {
            const bacteriaToUse =
              bacteriaName === "christensenellaceae"
                ? "Christensenellaceae"
                : "Christensenellaceae_R-7_group";
          } else {
            console.log("yey");
            if (lowercasedTop35.includes(bacteriaName)) {
              const existingBacteria = this.goodBacteria.find(
                (x) => x.name.toLowerCase() === bacteriaName
              );
              if (existingBacteria) {
                existingBacteria.values.push(tempValues);
              } else {
                this.goodBacteria.push(temp);
              }
            }
          }
        }
        if (Object.values(this.division.bad).indexOf(element.bacteria) > -1) {
          if (this.badBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.badBacteria.find(
              (x) => x.name === element.bacteria
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.badBacteria.push(temp);
          }
        }
        if (
          Object.values(this.division.relevant).indexOf(element.bacteria) > -1
        ) {
          if (this.relevantBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.relevantBacteria.find(
              (x) => x.name === element.bacteria
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.relevantBacteria.push(temp);
          }
        }
      });
    });
    this.goodBacteria.sort((a, b) => {
      const bacteriaA = a.name.toLowerCase();
      const bacteriaB = b.name.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });

    this.badBacteria.sort((a, b) => {
      const bacteriaA = a.name.toLowerCase();
      const bacteriaB = b.name.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });

    this.relevantBacteria.sort((a, b) => {
      const bacteriaA = a.name.toLowerCase();
      const bacteriaB = b.name.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });
    this.relevantBacteria.forEach((elem) => {
      if (elem.name === "Clostridium_sensu_stricto_1") {
        elem.name = "Clostridium";
      }
    });
    this.goodBacteria.forEach((elem) => {
      if (elem.name === "Christensenellaceae_R-7_group") {
        elem.name = "Christensenellaceae";
      }
    });
  }

  getBacteriaLevel(bacteria) {
    // Destructure the range for readability
    const [minValue, maxValue] = bacteria.range;

    // if (bacteria.value != 0) {
    let val = 0;

    // Determine the position based on the bar range
    if (bacteria.value < minValue) {
      // Value is below the minimum, needle should be at the far left
      val = 0;
    } else if (bacteria.value > maxValue) {
      val = 100;
      // Value is above the maximum, needle should be at the far right
    } else if (bacteria.status === "low") {
      // Left bar calculation
      val = (15 * (bacteria.value - 0)) / (minValue - 0);
    } else if (bacteria.status === "avg") {
      // Middle bar calculation
      val = 15 + (70 * (bacteria.value - minValue)) / (maxValue - minValue);
    } else {
      // Right bar calculation
      let diff = ((maxValue - minValue) / 70) * 15;
      // console.log(minValue);
      // console.log(maxValue);
      // console.log(diff);
      val =
        85 + (15 * (bacteria.value - maxValue)) / (maxValue + diff - maxValue);
    }

    // Clamp the value to ensure it stays within the range [0, 100]
    val = Math.max(0, Math.min(100, val));
    // Return the calculated position
    return `calc(${val}%)`;
    // }
    // return `calc(${0}%)`;
  }
}
