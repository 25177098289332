import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { IOrder } from "../../interfaces/orders";
import { AuthenticationService } from "./api/login/authentication";
import { UserService } from "./api/users/users";
import { ThemeService } from "./theme";
import { GutMicrobiomeIndexComponent } from "./gut-microbiome-index/gut-microbiome-index.component";

declare var gtag: Function;
const PATRIS_LANGUAGE_MAPPING = {
  en: "en-patris",
  nl: "nl-patris",
  it: "it-patris",
  sk: "sk-patris",
  de: "de-patris",
};
const ASTROLAB_LANGUAGE_MAPPING = {
  en: "en-astrolab",
  es: "es-astrolab",
};
const GOINGGUTS_LANGUAGE_MAPPING = {
  en: "en-goingguts",
  nl: "nl-goingguts",
  it: "it-goingguts",
  de: "de-goingguts",
};
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "microbiome";
  activePage = "home";

  shoppingCartNumber = this.updateCartNumber();
  basket: IOrder;
  selectedLanguage: string;
  userToken: string;
  partnerVersion: string = "";

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    public auth: AuthenticationService,
    private router: Router,
    private themeService: ThemeService
  ) {
    if (
      window.location.hostname == "iprobio.gutexplorer.com" ||
      window.location.hostname == "www.iprobio.gutexplorer.com" ||
      window.location.hostname == "iprobio.test.gutexplorer.com" ||
      window.location.hostname == "www.iprobio.test.gutexplorer.com" ||
      window.location.hostname == "" ||
      window.location.hostname == "test.gutexplorer.com"
    ) {
      translate.setDefaultLang("sk");
      translate.use("sk");
      localStorage.setItem("language", "sk");
      this.selectedLanguage = "sk";
    } else if (localStorage.getItem("language")) {
      translate.setDefaultLang(localStorage.getItem("language"));
      translate.use(localStorage.getItem("language"));
      this.selectedLanguage = localStorage.getItem("language");
    } else {
      translate.setDefaultLang("nl");
      translate.use("nl");
      localStorage.setItem("language", "nl");
      this.selectedLanguage = "nl";
    }

    const navEndEvent$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag("config", "GTM-NCN2T3P", { page_path: e.urlAfterRedirects });
      gtag("config", "G-8CVL1B8WXC", { page_path: e.urlAfterRedirects });
    });
  }

  homeUrl: string;

  navLinks = {
    iprobio: {
      testResults: "/vysledky",
      passwordRecovery: "/obnova-hesla",
      accountCreation: "/vytvorit-ucet/customer",
      accountDetails: "/detaily-účtu",
      results: "/ucet/1",
      newSubject: "/nový-užívateľ",
      foodDiary: "/stravovací-denník",
      foodDiaryExample: "/príklad-potravinového-denníka",
      survey: "/prieskum",
      surveyAnswers: "/odpovede-prieskumu",
      dashboard: "/moje-výsledky/",
      diversity: "/diverzita-baktérií",
      targetValues: "/cieľové-hodnoty-živín",
      gutHealth: "/podkategória",
      levels: "/hladiny-baktérií",
      takeHomeMessage: "/zhrnutie",
      subjectDetails: "/detaily-pacienta",
      supplementAdvice: "/supplement-advice",
      gutMicrobiomeIndex: "/gut-microbiome-index",
    },
    default: {
      testResults: "/test-results",
      passwordRecovery: "/password-recovery",
      accountCreation: "/create-account/customer",
      accountDetails: "/account-details",
      results: "/account-category/1",
      newSubject: "/new-user",
      foodDiary: "/food-diary",
      foodDiaryExample: "/food-diary-example",
      survey: "/survey",
      surveyAnswers: "/survey-answers",
      dashboard: "/dashboard/",
      diversity: "/bacteria-diversity",
      targetValues: "/target-nutrient-values",
      gutHealth: "/subcategory",
      levels: "/bacteria-levels",
      takeHomeMessage: "/take-home-message",
      subjectDetails: "/subject-details",
      supplementAdvice: "/supplement-advice",
      gutMicrobiomeIndex: "/gut-microbiome-index",
    },
  };

  activeLinks = this.navLinks["default"];

  ngOnInit() {
    this.homeUrl = "https://innerbuddies.com/";
    let fontClass = "default-font"; // Default font
    if (
      window.location.hostname == "iprobio.gutexplorer.com" ||
      window.location.hostname == "www.iprobio.gutexplorer.com" ||
      window.location.hostname == "iprobio.test.gutexplorer.com" ||
      window.location.hostname == "www.iprobio.test.gutexplorer.com" ||
      window.location.hostname == "" ||
      window.location.hostname == "test.gutexplorer.com"
    ) {
      this.themeService.setTheme("iprobio");
      this.selectCorrectNavDesign();
      fontClass = "iprobio-font";
      this.partnerVersion = "iprobio";
      document.getElementById("iprobioContact").style.display = "flex";
      this.homeUrl = "https://iprobio.gutexplorer.com/ucet/1";
      document
        .querySelector("#appIcon")
        .setAttribute(
          "href",
          "./assets/favicons/" + this.partnerVersion + ".png"
        );
      this.activeLinks = this.navLinks.iprobio;
    }
    if (
      window.location.hostname == "patris.gutexplorer.com" ||
      window.location.hostname == "www.patris.gutexplorer.com" ||
      window.location.hostname == "www.patris.test.gutexplorer.com" ||
      window.location.hostname == "patris.test.gutexplorer.com"
    ) {
      this.themeService.setTheme("patris_health");
      fontClass = "patris-font";
      this.partnerVersion = "patris_health";

      const savedLanguage = localStorage.getItem("language") || "en";
      let languageToUse = PATRIS_LANGUAGE_MAPPING[savedLanguage] || "en-patris";
      this.translate.setDefaultLang(languageToUse);
      this.translate.use(languageToUse);

      document.getElementById("patrisHealthContact").style.display = "flex";
      this.homeUrl = "https://www.patris-health.com/";
      document
        .querySelector("#appIcon")
        .setAttribute("href", "./assets/favicons/patris.png");
      this.activeLinks = this.navLinks["default"];
    }
    if (
      window.location.hostname === "astrolab.gutexplorer.com" ||
      window.location.hostname === "www.astrolab.gutexplorer.com" ||
      window.location.hostname == "www.astrolab.test.gutexplorer.com" ||
      window.location.hostname == "astrolab.test.gutexplorer.com" ||
      window.location.hostname == "localhost" 
    ) {
      this.themeService.setTheme("astrolab");
      fontClass = "astrolab-font";
      this.partnerVersion = "astrolab";

      const savedLanguage = localStorage.getItem("language") || "en";
      let languageToUse =
        ASTROLAB_LANGUAGE_MAPPING[savedLanguage] || "en-astrolab";
      this.translate.setDefaultLang(languageToUse);
      this.translate.use(languageToUse);

      document.getElementById("astrolabContact").style.display = "flex";
      this.homeUrl = "https://astrolab-bio.com.co/";
      document
        .querySelector("#appIcon")
        .setAttribute("href", "./assets/favicons/astrolab.png");
      this.activeLinks = this.navLinks["default"];
    }
    if (
      window.location.hostname === "goingguts.gutexplorer.com" ||
      window.location.hostname === "www.goingguts.gutexplorer.com" ||
      window.location.hostname == "www.goingguts.test.gutexplorer.com" ||
      window.location.hostname == "goingguts.test.gutexplorer.com"
    ) {
      this.themeService.setTheme("goingguts");
      fontClass = "default_font";
      this.partnerVersion = "goingguts";

      const savedLanguage = localStorage.getItem("language") || "en";
      let languageToUse =
        GOINGGUTS_LANGUAGE_MAPPING[savedLanguage] || "en-goingguts";
      this.translate.setDefaultLang(languageToUse);
      this.translate.use(languageToUse);

      document.getElementById("goinggutsContact").style.display = "flex";
      this.homeUrl = "";
      document
        .querySelector("#appIcon")
        .setAttribute("href", "./assets/favicons/goingguts.png");
      this.activeLinks = this.navLinks["default"];
    }
    if (
      window.location.hostname == "test.innerbuddies.com" ||
      window.location.hostname == "www.test.innerbuddies.com" ||
      window.location.hostname == "portal.innerbuddies.com"
    ) {
      this.homeUrl = "https://www.test.innerbuddies.com/";
      this.activeLinks = this.navLinks["default"];
    }

    // Just in case
    this.selectCorrectNavDesign();
    this.hideNonPartnerElements();

    // this.userToken = this.readCookie("token");
    // if (this.userToken != "") {
    //   localStorage.setItem("token", this.userToken);
    // } else {
    //   this.router.navigate(["not-logged-in"]);
    // }
    document.body.classList.add(fontClass);
    this.hideLogin();
  }

  updateCartNumber() {
    if (localStorage.getItem("Basket")) {
      this.basket = JSON.parse(localStorage.getItem("Basket"));
      let total = 0;
      if (!this.basket.order_items) return false;
      for (const item of this.basket.order_items) {
        total += item.quantity;
      }
      localStorage.setItem("shoppingCartNumber", total.toString());
      return total.toString();
    }
    return "0";
  }

  onActivate(event) {
    // window.scroll(0,0);

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  /**this.toggleHome = !this.toggleHome;
   this.toggleTestResults = !this.toggleTestResults;
   this.toggleStart = !this.toggleStart;
   this.toggleInfo = !this.toggleInfo;
   this.toggleAccount = !this.toggleAccount;  **/

  async changeLanguage(reload: boolean) {
    let languageToUse = this.selectedLanguage;
    if (this.partnerVersion === "patris_health") {
      languageToUse =
        PATRIS_LANGUAGE_MAPPING[this.selectedLanguage] || "en-patris";
    } else if (this.partnerVersion === "astrolab") {
      languageToUse =
        ASTROLAB_LANGUAGE_MAPPING[this.selectedLanguage] || "en-astrolab";
    } else if (this.partnerVersion === "goingguts") {
      languageToUse =
        GOINGGUTS_LANGUAGE_MAPPING[this.selectedLanguage] || "en-goingguts";
    }
    this.translate.setDefaultLang(languageToUse);
    this.translate.use(languageToUse);
    localStorage.setItem("language", this.selectedLanguage);

    let languageCodeId = 1;
    if (this.selectedLanguage === "nl") {
      languageCodeId = 2;
    }
    if (this.selectedLanguage === "it") {
      languageCodeId = 3;
    }
    if (this.selectedLanguage === "sk") {
      languageCodeId = 4;
    }
    if (this.selectedLanguage === "de") {
      languageCodeId = 6;
    }
    if (this.selectedLanguage === "es") {
      languageCodeId = 7;
    }
    await await this.userService.UpdateUserLanguageNew(this.selectedLanguage);
    if (reload) {
      window.location.reload();
    }
  }

  hideLogin() {
    if (this.partnerVersion == "iprobio") {
      if (!this.auth.isAuthenticated()) {
        console.log("test");
        document.getElementById("profile-tab-iprobio")!.style.display = "none";
      } else {
        document.getElementById("profile-tab-iprobio")!.style.display = "";
      }
    } else if (
      this.partnerVersion == "patris_health" ||
      this.partnerVersion == "astrolab" ||
      this.partnerVersion == "goingguts"
    ) {
      document.getElementById("profile-tab-default")!.style.display = "none";
      document.getElementById("login-tab-default")!.style.display = "none";
    } else {
      if (!this.auth.isAuthenticated()) {
        document.getElementById("profile-tab-default")!.style.display = "none";
        document.getElementById("login-tab-default")!.style.display = "";
      } else {
        document.getElementById("profile-tab-default")!.style.display = "";
        document.getElementById("login-tab-default")!.style.display = "none";
      }
    }
  }

  readCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  hideNonPartnerElements() {
    let elements = Array.from(
      document.getElementsByClassName("none-partner-elements")
    );
    console.log(elements);
    for (const element of elements) {
      element.setAttribute("style", "display: none");
    }
  }

  selectCorrectNavDesign() {
    if (this.partnerVersion == "iprobio") {
      document.getElementById("profile-tab-default")!.style.display = "none";
      document.getElementById("login-tab-default")!.style.display = "none";
      document.getElementById("language")!.style.display = "none";
    } else {
      document.getElementById("profile-tab-iprobio")!.style.display = "none";
    }
  }

  logOut() {
    this.auth.logout();
    this.hideLogin();
    this.selectCorrectNavDesign();
  }

  get isIprobioPartner() {
    return this.partnerVersion === "iprobio";
  }

  get isInnerBuddiesPartner() {
    return this.partnerVersion === "";
  }

  get isPatrisHealthPartner() {
    return this.partnerVersion === "patris_health";
  }
  get isGoinggutsPartner() {
    return this.partnerVersion === "goingguts";
  }
  get isAstrolabPartner() {
    return this.partnerVersion === "astrolab";
  }

  getEnvironmentType(): true | false {
    const testHostnames = [
      "test.gutexplorer.com",
      "www.test.gutexplorer.com",
      "iprobio.test.gutexplorer.com",
      "www.iprobio.test.gutexplorer.com",
      "patris.test.gutexplorer.com",
      "www.patris.test.gutexplorer.com",
      "astrolab.test.gutexplorer.com",
      "www.astrolab.test.gutexplorer.com",
      "goingguts.test.gutexplorer.com",
      "www.goingguts.test.gutexplorer.com",
      "test.innerbuddies.com",
      "www.test.innerbuddies.com",
      "localhost",
    ];

    return testHostnames.includes(window.location.hostname) ? true : false;
  }
}
