import { environment } from "./environments/environment";

export default function setupAxiosPlugin({ $axios }) {
  // Ensure $axios is an Axios instance
  if (!$axios || typeof $axios.interceptors === "undefined") {
    throw new Error("$axios must be an Axios instance");
  }


  // Add a response interceptor to handle errors globally
  $axios.interceptors.response.use(
    (response) => response, // Pass through successful responses
    async (error) => {
      if (error.response) {
        // Handle 401 Unauthorized error
        if (error.response.status === 401) {
          console.warn("Unauthorized access detected. Redirecting to login...");
          
          localStorage.removeItem("token"); // Clear any existing token
          sessionStorage.removeItem("token");
          window.location.href = "/"; // Navigate to the login or home page
        }
      } else {
        console.error("Error occurred without a response:", error);
      }

      // Reject the error to allow further handling
      return Promise.reject(error);
    }
  );
}
